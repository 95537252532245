import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AddPolicyDocComponent } from '../add-policy-doc/add-policy-doc.component';
import { Router } from '@angular/router';
import { AdminPolicyDocService } from 'src/app/service/admin-policy-doc/admin-policy-doc.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminPolicyDocument } from 'src/app/models/admin-policy-doc/admin-pollicy-document.model';
import { AdminPolicyDocBenefit } from 'src/app/models/admin-policy-doc/admin-policy-doc-benefit.model';
import { AdminPolicyDocProduct } from 'src/app/models/admin-policy-doc/admin-policy-doc-product.model';

@Component({
  selector: 'app-update-policy-doc',
  templateUrl: './update-policy-doc.component.html',
  styleUrls: ['./update-policy-doc.component.scss']
})
export class UpdatePolicyDocDialogComponent extends AddPolicyDocComponent implements OnInit {
  existingFileName: string = '';
  selectedBenefit: AdminPolicyDocBenefit = null;
  selectedProduct: AdminPolicyDocProduct = null;
  productList: AdminPolicyDocProduct[] = [];
  isLoadingData: boolean = false;

  constructor (
    public adminPolicyDocService: AdminPolicyDocService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    public router: Router,
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<UpdatePolicyDocDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AdminPolicyDocument
  ) {
    super(adminPolicyDocService, sharedFunction, loginService, router, renderer);
  }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.adminPolicyDocService.getCompaniesBenefitsObservable().subscribe((response) => {
      if (response && response.companies.length > 0 && response.benefits.length > 0) {
        // get companies and benefits
        this.companyList = response.companies;
        this.benefitList = response.benefits;
        this.policyDoc = this.data;
        this.newCompany = this.companyList.find(c => c.CompanyId === this.data.NewCompanyId);
        this.oldCompany = this.companyList.find(c => c.CompanyId === this.data.OldCompanyId) || null;
        this.existingFileName = this.data.PdfFileLink.substring(this.data.PdfFileLink.lastIndexOf('/') + 1);
        this.selectedBenefit = this.benefitList.find(b => b.BenefitId === this.data.BenefitId);

        this.getProducts(this.selectedBenefit);
      }
    });
  }

  // when selecting a benefit, then call api to get its product list
  getProducts(benefit: AdminPolicyDocBenefit): void {
    this.productList = [];
    this.adminPolicyDocService.getAdminPolicyDocProductsObservable(benefit.BenefitId).subscribe(data => {
      this.productList = data;
      this.productList.unshift(AdminPolicyDocProduct.createEmptyObject("All"));
      this.selectedProduct = this.productList.find(p => p.ProductId === this.data.ProductId) || this.productList[0];
      this.isLoadingData = false;
    });
  }


  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    this.policyDoc.OldCompanyId = this.oldCompany?.CompanyId || null;
    this.policyDoc.NewCompanyId = this.newCompany.CompanyId;
    this.policyDoc.BenefitId = this.selectedBenefit.BenefitId;
    this.policyDoc.ProductId = this.selectedProduct.ProductId === -1 ? null : this.selectedProduct.ProductId;
    this.checkIsValid();
    if (this.isValidData) {
      this.isLoadingData = true;
      this.adminPolicyDocService.addOrUpdateAdminPolicyDoc("document", this.policyDoc, this.selectedFiles && this.selectedFiles.length > 0 ? this.selectedFiles[0] : null, (response) => {
        this.isLoadingData = false;
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 3000);
        this.loginService.doGoogleTracking("InternalAdminPortal", "policyDocument", "update policy doc");
        this.dialogRef.close(this.policyDoc);
      });
    }

  }

  checkIsValid(): void {
    if (!this.policyDoc.DocumentName || !this.policyDoc.EffectiveDate || !this.policyDoc.Description || !this.newCompany) {
      this.isValidData = false;
    } else {
      this.isValidData = true;
    }
  }

}
