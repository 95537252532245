import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';

@Component({
    selector: 'quote-p-benefit',
    templateUrl: './quote-p-benefit.html',
    styleUrls: ['./quote-p-benefit.scss'],
})
export class QuotePersonalBenefitComponent implements OnInit {
    @Output() changeSelectedIndex = new EventEmitter();
    isGoBackNext: boolean = false;
    quoteRequiredData: QuoteRequiredData;
    isDevMode: boolean = true;
    accessRole: AccessRole = new AccessRole();

    constructor(
        public quoteStepClient: QuoteStepClient,
        public quoteStepBenefit: QuoteStepBenefit,
        public route: ActivatedRoute,
        public router: Router,
        public sharedFunction: SharedFunctionService
    ) {}

    ngOnInit(): void {
        this.isDevMode = this.sharedFunction.isQaSite();
        this.quoteStepBenefit.init();
    }

    passQuoteDataListAndCallBulkCrunch() {
        this.quoteStepBenefit.validation();
        if (this.quoteStepBenefit.errorMessage.length === 0) {
            this.quoteStepBenefit.quoteDataList = this.quoteStepClient.quoteDataList;
            this.changeSelectedIndex.emit(3);
        }
    }
    
    sendBulkQuoteResult() {
        this.quoteStepBenefit.validation();
        if (this.quoteStepBenefit.errorMessage.length === 0) {
            this.quoteStepBenefit.quoteDataList = this.quoteStepClient.quoteDataList;
            this.quoteStepBenefit.doSendBulkQuoteResult(() => this.changeSelectedIndex.emit(0));
        }
    }
}
