import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminKiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { MorningStarFund } from 'src/app/models/kiwimonster/kiwimonster-morning-star-fund.model';
import { QmKmSchemeProductFund } from 'src/app/models/kiwimonster/kiwimonster-scheme-product-fund.model';
import { KiwiMonsterFundsService } from 'src/app/service/kiwimonster/kiwimonster-funds.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { KiwiMonsterMorningStarDialogComponent } from '../kiwimonster-morningstar-dialog/kiwimonster-morningstar-dialog.component';
import { SelectItemStruct } from 'src/app/models/select.item';

@Component({
  selector: 'app-kiwimonster-fund-detail',
  templateUrl: './kiwimonster-fund-detail.component.html',
  styleUrls: ['./kiwimonster-fund-detail.component.scss']
})
export class KiwiMonsterFundDetailComponent implements OnInit {
  linkId: number;
  currentKiwiMonsterFund: AdminKiwiMonsterFund;
  currentQmKmSchemeProductFund: QmKmSchemeProductFund;
  currentMorningStarFund: MorningStarFund;
  pageHeader: string = '';
  morningStarDetailsTitle: string = '';

  constructor (
    public route: ActivatedRoute,
    private kiwiMonsterFundsService: KiwiMonsterFundsService,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.linkId = Number(this.route.snapshot.paramMap.get('linkId'));
    this.loadData();
  }

  loadData(): void {
    this.kiwiMonsterFundsService.showDinoLoading();
    this.kiwiMonsterFundsService.getAdminKiwiMonsterDetail(this.linkId, (response) => {
      if (response && response.LinkId === this.linkId) {
        // set object data
        this.currentKiwiMonsterFund = response;
        this.currentQmKmSchemeProductFund = this.currentKiwiMonsterFund.Detail.QmKmSchemeProductFund;
        this.currentMorningStarFund = this.currentKiwiMonsterFund.Detail.MorningStarFund;

        // set header
        this.pageHeader = `${ this.currentQmKmSchemeProductFund.OfferNumber } - ${ this.currentQmKmSchemeProductFund.SchemeName } (${ this.currentQmKmSchemeProductFund.SchemeNumber }) - ${ this.currentQmKmSchemeProductFund.FundName } (${ this.currentQmKmSchemeProductFund.FundNumber })`;

        // morningStarDetailsTitle
        this.morningStarDetailsTitle = this.currentMorningStarFund ? this.currentMorningStarFund['FSCBI-FundLegalName'] : 'No MorningStar Fund';
      }
      this.kiwiMonsterFundsService.closeDinoLoading();
    });
  }

  openMorningStarDialog(): void {
    // show popup window
    const dialogRef = this.dialog.open(KiwiMonsterMorningStarDialogComponent, {
      data: this.currentKiwiMonsterFund,
      width: '800px',
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: SelectItemStruct | null) => {
      if (result) {
        this.kiwiMonsterFundsService.showDinoLoading();
        this.kiwiMonsterFundsService.updateLinkMBIEAndMorningStar(this.currentKiwiMonsterFund.LinkId, this.currentQmKmSchemeProductFund.Id, result.Value, (response) => {
          if (response) {
            this.loadData();
          }
          this.kiwiMonsterFundsService.closeDinoLoading();
        });
      }
    })
  }

}
