import { Component, OnInit } from '@angular/core';
import { AdminUserMenuControl } from 'src/app/models/admin/user.menu.control';
import { DataLoader } from 'src/app/service/data.loader';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isDevMode: boolean = false;
  adminUserMenus: AdminUserMenuControl;

  constructor (
    public loginService: LoginService,
    private dataLoader: DataLoader,
    private sharedFunction: SharedFunctionService
  ) { }

  ngOnInit() {
    this.loginService.checkLoginStatus();
    this.isDevMode = this.sharedFunction.isQaSite();
    this.adminUserMenus = this.dataLoader.getUserMenu();
  }

}
