import { Component, OnInit, } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { SharedFunctionService } from 'src/app/service/shared.function.service';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit-selector',
    templateUrl: './quote-p-benefit-selector.html',
    styleUrls: ['./quote-p-benefit-selector.scss']
})
export class QuotePersonalBenefitSelectorComponent implements OnInit {

    isDevMode: boolean = false;
    

    constructor(
        public quoteStepBenefit: QuoteStepBenefit,
        private sharedFunction: SharedFunctionService
    ) {

    }

    ngOnInit(): void {
        this.isDevMode = this.sharedFunction.isQaSite();
    }


}
