import { Injectable, isDevMode } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { PeopleEntity } from '../models/people';
import { Provider } from '../models/provider';
import { QuoteRequiredData } from '../models/quote.required.data';
import { UserDeviceModel } from '../models/two.factor.login.models/user.device.model';
import { VerificationMethodModel } from '../models/two.factor.login.models/verification.method.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs'
import { KeyValue } from '@angular/common';
import { GeneralMessageDialogSetting } from '../models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { QuoteDataObj } from '../models/quote.data.obj';

@Injectable({
    providedIn: 'root',
})
export class SharedFunctionService {


    hasTopAlert: boolean = false;
    quoteRequiredData: QuoteRequiredData;


    constructor(
        private router: Router,
        public snackBar: MatSnackBar,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        this.hasTopAlert = false;
    }

    public progressStep = {
        QuoteStep1: { value: 1, rote: '/quote/client' },
        QuoteStep2: { value: 2, rote: '/quote/personal/benefit' },
        QuoteStep3: { value: 3, rote: '/quote/compare' },

        QuoteStep3Chart: { value: 11, rote: '/quote/compare' },
        QuoteStep3Document: { value: 12, rote: '/quote/compare' },
        QuoteStep3Research: { value: 13, rote: '/quote/compare' },
        QuoteStep3H2H: { value: 14, rote: '/quote/compare' },
        QuoteStep3Underwriting: { value: 15, rote: '/quote/compare' },

        QuoteStep4: { value: 4, rote: '/quote/report' },

        NeedAnalysisNature: { value: 5.1, rote: '/needanalysis/nature' },
        NeedAnalysisObjectives: { value: 5.2, rote: '/needanalysis/objectives' },
        NeedAnalysisScope: { value: 5.3, rote: '/needanalysis/scope' },
        NeedAnalysisExisting: { value: 5.4, rote: '/needanalysis/existing' },
        NeedAnalysisFinancial: { value: 5.5, rote: '/needanalysis/financial' },
        NeedAnalysisCalculations: { value: 5.6, rote: '/needanalysis/calculations' },
        NeedAnalysisCalculationsNotePad: { value: 5.7, rote: '/needanalysis/recommendationnotepad' },
    };

    isNullOrEmptyString(value: string): boolean {
        let v = false;

        if (value === null) {
            v = true;
        } else if (value === undefined) {
            v = true;
        } else if (value === '') {
            v = true;
        } else if (value === 'undefined') {
            v = true;
        }

        return v;
    }


    isNullOrEmptyList(value: any[]) {
        let v = false;

        if (value === null) {
            v = true;
        } else if (value === undefined) {
            v = true;
        } else if (value.length <= 0) {
            v = true;
        }

        return v;
    }


    getNumberValue(value: any, defaultValue: number): number {
        let returnValue: number = defaultValue;
        if (value && !isNaN(value)) {
            returnValue = Number(value);
        }
        return returnValue;
    }

    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure

            // TODO: better job of transforming error for user consumption
            
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = 'Alert';
            messageSetting.NeedYesBtn = false;

            if (error.status === 401 && !this.hasTopAlert) {
                this.hasTopAlert = true;
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-NewDeviceReLogin');
                this.confirmDialog.confirm(messageSetting);
                // do log out
                // 1. clear all session data
                // -- window.sessionStorage.clear();
                // reload page
                // -- window.location.assign(window.location.origin);
            } else if (error.status === 402) {
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-AccessDeny');
                this.confirmDialog.confirm(messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
            } else if ((error.status < 200 || 299 > error.status) && !this.hasTopAlert) {
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-SomethingWrong');
                this.confirmDialog.confirm(messageSetting);
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    public handleErrorWithoutAlert<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    // 1: Male, 2: Female, 3: Couple, 4: Male+Male, 5: Female+Female
    getSavedClientUserType(peopleEntity: PeopleEntity[]): number {

        let value: number = 0;

        if (peopleEntity && peopleEntity.length > 0) {
            for (let c of peopleEntity) {
                if (c.IsChild === false) {
                    if (value === 0) {
                        // if first client
                        value = c.Gender; // set first client gender
                    } else if (value === 1) {
                        // if already has first client gender and it is male
                        if (c.Gender === 1) {
                            value = 4; // if second client still male then show 2 male
                        } else {
                            value = 3; // if second client is female then show couple
                        }
                    } else {
                        // if first client is female
                        if (c.Gender === 2) {
                            value = 5; // if second client is female then show 2 female
                        } else {
                            value = 3; //  if second client is male then show male.
                        }
                    }
                }
            }
        }

        return value;
    }

    removeDuplicates(arr: any[]): any[] {
        let uniqueArray = [];
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (uniqueArray.indexOf(arr[i]) === -1) {
                    uniqueArray.push(arr[i]);
                }
            }
        }

        return uniqueArray;
    }


    isAmp(provider: Provider): boolean {
        let value = false;
        if (provider.ProviderId === 8 || provider.ProviderId === 12) {
            value = true;
        }

        return value;
    }


    getUser2FDeviceMessage(userDeviceModel: UserDeviceModel, quoteRequiredData: QuoteRequiredData): string {
        let message: string = '';

        if (userDeviceModel && userDeviceModel.Message) {
            for (let m of quoteRequiredData.User2FactorLoginMessage) {
                if (m.MessageId === userDeviceModel.Message.MessageId) {
                    message = m.Message;
                    break;
                }
            }

            // replace some required data.
            if (userDeviceModel.VerificationMethod !== null) {
                switch (userDeviceModel.Message.MessageId) {
                    case 3:
                        message = message.replace('{{NumberOfDevice}}', userDeviceModel.VerificationMethod.MaxDevices.toString());
                        break;
                    case 9:
                        message = message.replace('{{Value}}', userDeviceModel.VerificationMethod.Value);
                        break;
                    default:
                        message = message;
                        break;
                }
            }

        }
        return message;
    }

    getUser2FVerificationMethodMessage(verificationMethod: VerificationMethodModel, quoteRequiredData: QuoteRequiredData): string {
        let message: string = '';

        if (verificationMethod && verificationMethod.Message) {
            for (let m of quoteRequiredData.User2FactorLoginMessage) {
                if (m.MessageId === verificationMethod.Message.MessageId) {
                    message = m.Message;
                    switch (m.MessageId) {
                        case 9:
                            message = message.replace('{{Value}}', verificationMethod.Value);
                            break;
                    }
                    break;
                }
            }

        }
        return message;
    }

    getUser2FSYSErrorMessage(quoteRequiredData: QuoteRequiredData) {
        let message: string = '';

        for (let m of quoteRequiredData.User2FactorLoginMessage) {
            if (m.MessageId === 2) {
                message = m.Message;
                break;
            }
        }

        return message;
    }

    isJSON(str): boolean {
        if (typeof str == 'string') {
            try {
                var obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                    return true;
                } else {
                    return false;
                }

            } catch (e) {
                return false;
            }
        }
    }


    // getHelpMessageByCode function and messages in quoteRequiredData.Help are not used in web v4
    getHelpMessageByCode(code: string, quoteRequiredData: QuoteRequiredData): string {
        let value = '';

        for (let v of quoteRequiredData.Help) {
            if (v.PageCode === code) {
                value = v.message;
                break;
            }
        }

        return value;
    }


    getFrequencyName(frequency: number): string {
        switch (frequency) {
            case 1: return 'Annual';
            case 2: return 'Half Year';
            case 4: return 'Quarterly';
            case 12: return 'Monthly';
            case 24: return 'Fortnightly';
            default: return '';
        }
    }


    setRequiredData(requiredData: QuoteRequiredData) {
        this.quoteRequiredData = requiredData;
    }

    getUiMessageByCode(code: string, defaultMessage: string = ''): string {
        let message: string = defaultMessage;

        if (this.quoteRequiredData && this.quoteRequiredData.UIMessages && this.quoteRequiredData.UIMessages.length > 0) {
            for (let m of this.quoteRequiredData.UIMessages) {
                if (m.Code === code) {
                    message = m.Message;
                    break;
                }
            }
        }
        return message;
    }

    convertDateTimeToNZFormat(datetime: Date): string {
        let value: string = '';

        if (datetime) {
            value = dayjs(datetime).format('DD MMM YY, hh:mm A');
        } else {
            value = dayjs(new Date()).format('DD MMM YY, hh:mm A');
        }

        return value;
    }


    getInvoiceStatusName(status: number, requiredData: QuoteRequiredData): string {
        let name: string = 'Other';

        if (requiredData.InvoiceStatus) {
            for (let s of requiredData.InvoiceStatus) {
                if (s.Value === status) {
                    name = s.Name;
                    break;
                }
            }
        }


        return name;
    }
    
    getNameFromValue(optionList:QuoteDataObj[],value:number | null): string {
        const matchingOption = optionList.find((option) => option.Value === value);
        return matchingOption?matchingOption.Name:'n/a'
    }

    openSnackBar( messageCode: string = '', action: string, duration: number = 1000, message:string ='' ){
        if(message){
            this.snackBar.open(message, action, {
                duration: duration,
            });
        } else {
            this.snackBar.open(this.getUiMessageByCode(messageCode), action, {
                duration: duration,
            });
        }
    }

    // Preserve original property order for keyvalue pipe
    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
         return 0;
    }

    getQmUserGuideHelpMessageByCode(code: string): string {
        let message = '';
        if (
            this.quoteRequiredData &&
            this.quoteRequiredData.QmUserGuideHelpMessages &&
            this.quoteRequiredData.QmUserGuideHelpMessages.length > 0
        ) {
            for (let item of this.quoteRequiredData.QmUserGuideHelpMessages) {
                if (item.MessageCode === code) {
                    message = item.Help;
                    break;
                }
            }
        }
        return message;
    }
    
    mapSelectOptions(value: any, allOptions: any[], selectProperty: string): any[] {
        const inputValue = typeof value === 'string' ? value : value ? value.toString() : '';
        return inputValue ? this.selectFilter(inputValue as string, allOptions, selectProperty) : allOptions;
    }

    private selectFilter(value: string, allOptions: any[], selectProperty: string): any[] {
        const filterValue = value.toLowerCase();
        if (selectProperty) {
            const optionsLength: number = allOptions.filter(
                (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) > -1
            ).length;

            if (optionsLength > 0) {
                if (
                    allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) === 0
                    ).length > 0
                ) {
                    // return options that start with filter value
                    return allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) === 0
                    );
                } else {
                    // return options that include filter value
                    return allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) > -1
                    );
                }
            } else {
                // return empty array;
                return [];
            }
        } else {
            // not object option list
            return allOptions.filter((option) => option.toString().toLowerCase().indexOf(filterValue) > -1).length > 0
                ? allOptions.filter((option) => option.toString().toLowerCase().indexOf(filterValue) > -1)
                : [];
        }
    }
    
    optionDisplayFn(obj: any, displayValue: string): string {
        return obj && obj[displayValue] ? obj[displayValue] : obj;
    }
    
    // get values from {key:value, key:value, ...} 
    getObjectValues(obj: any): any[]{
        return Object.values(obj);
    }
    
    getObjectPropertyNames(obj: any): string[]{
        return Object.keys(obj).sort();
    }


    isQaSite() : boolean{
        return isDevMode();
    }
}
