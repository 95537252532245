<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>QM KiwiMonster Funds</h5>
    </li>

    <!-- search fields -->
    <li class='list-group-item'>
      <div class='row'>
        <!-- keyword search -->
        <div class='col-12 col-md-6'>
          <mat-form-field>
            <input matInput
                   placeholder="Search"
                   (keyup)="textFilter($event.target.value)">
          </mat-form-field>
        </div>
        <!-- requires review only -->
        <div class='col-12 col-md-6 align-self-center'>
          <mat-checkbox [(ngModel)]="onlyRequiresReview" class='mb-0' (change)='requiresReviewFilter()'>
            Display Require Review Funds only.
          </mat-checkbox>
        </div>
      </div>
    </li>

    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>

    <!-- funds table -->
    <li class="list-group-item border-top-dashed" *ngIf="allFunds.length > 0 && !errorMessage">
      <div class='table-responsive mt-3'>
        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-info table-hover">
          <!-- LinkId -->
          <ng-container matColumnDef="LinkId">
            <th *matHeaderCellDef>ID</th>
            <td *matCellDef="let fund">
              <button mat-button class='text-blue' title='detail'
                      (click)='openFundDetail(fund);loginService.doGoogleTracking("InternalAdminPortal", "adminKiwiMonster", "go to fund detail");'>
                {{fund.LinkId}}
              </button>
            </td>
          </ng-container>

          <!-- MBIE Fund Name -->
          <ng-container matColumnDef="QmMBIEFundName">
            <th *matHeaderCellDef>Fund Name (MBIE)</th>
            <td *matCellDef="let fund">
              <button mat-button class='text-blue' title='detail'
                      (click)='openFundDetail(fund);loginService.doGoogleTracking("InternalAdminPortal", "adminKiwiMonster", "go to fund detail");'>
                {{fund.QmMBIEFundName}}
              </button>
            </td>
          </ng-container>
          <!-- Morning Start Fund Name -->
          <ng-container matColumnDef="QmMorningStartFundName">
            <th *matHeaderCellDef>Fund Name (MorningStar)</th>
            <td *matCellDef="let fund">{{fund.QmMorningStartFundName}}</td>
          </ng-container>
          <!-- Requires Review -->
          <ng-container matColumnDef="Selected">
            <th *matHeaderCellDef>Requires Review</th>
            <td *matCellDef="let fund" [ngClass]="{'text-danger font-weight-bolder': fund.QmMBIEFund.Selected}">
              {{fund.QmMBIEFund.Selected ? 'YES' : 'NO'}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <!-- paginator -->
        <mat-paginator class='qm-paginator'></mat-paginator>
      </div>
    </li>
  </ul>
</div>