import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { QuoteService } from '../../service/quote.service';
import { Provider } from '../../models/provider';
import { Benefit } from '../../models/benefit';
import { QuoteReportDialogComponent } from './quote-report-dialog.component';
import { UserProviderSettingDialog } from '../setting/quote-setting-dialog';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SpecialQuoteSetting } from 'src/app/models/special.quote.setting';
import { CurrentQuote } from 'src/app/models/current.quote';
import { QuoteResultItem } from 'src/app/models/quote.result.item';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-result-dialog.component',
    templateUrl: './quote-result-dialog.component.html',
    styleUrls: ['./quote-result-dialog.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class QuoteResultDialogComponent implements OnInit {


    selectedProviderIndex: number = 0;
    isLoadingData: boolean = false;
    selectedProvider: Provider;

    timestamp: number = 123456789;

    allQuoteResults: QuoteResultItem[];
    showNeedReCrunchAlert:boolean = false;


    constructor(
        public loginService: LoginService,
        private quoteService: QuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<QuoteResultDialogComponent>,
        public sharedFunction: SharedFunctionService,
        @Inject(MAT_DIALOG_DATA) public data: CurrentQuote) {

    }


    ngOnInit(): void {

        this.timestamp = new Date().getTime();

        this.getFrequencyName();
        this.selectedProvider = new Provider();
        this.selectedProvider.ProviderName = '';


        this.allQuoteResults = [];

        // load quote result into allQuoteResults
        for (let result of this.data.QuoteResult.QuoteResultList) {
            result.Provider.IsBankResult = false;
            this.allQuoteResults.push(result);
        }
        // load bank quote result into allQuoteResults
        if (this.data.QuoteResult.BankQuoteResultList) {
            for (let result of this.data.QuoteResult.BankQuoteResultList) {
                result.Provider.IsBankResult = true;
                this.allQuoteResults.push(result);
            }
        }


        // set default provider index
        for (let i = 0; i < this.allQuoteResults.length; i++) {
            if (this.allQuoteResults[i].Provider.IsSelected === true) {
                this.selectedProviderIndex = i;
                this.selectedProvider = this.allQuoteResults[i].Provider;
                break;
            }
        }

        if (this.selectedProvider.ProviderName === '' && this.allQuoteResults.length > 0) {
            this.selectedProviderIndex = 0;
            this.selectedProvider = this.allQuoteResults[0].Provider;
        }

        this.loadUserProviderSetting();

        this.trackingEvent(this.selectedProvider);
    }


    loadUserProviderSetting() {
        this.isLoadingData = true;
        this.quoteService.getUserProviderSetting((response) => {
            if (response) {
                // this.data.UserProviderSetting = response;
                for (let setting of response) {
                    let providerSetting = setting;
                    for (let result of this.data.QuoteResult.QuoteResultList) {
                        if (providerSetting.ProviderId === result.Provider.ProviderId) {
                            result.Provider.BenefitList = providerSetting.BenefitList;
                        }

                        if (result.Provider.ProviderId === 1) {
                            if (result.Provider.SpecialProviderSetting === 'VFVD') {
                                result.Provider.AIAVitalityDiscount = true;
                                result.Provider.AIAVitalityFeeDiscount = true;
                            } else if (result.Provider.SpecialProviderSetting === 'VF') {
                                result.Provider.AIAVitalityDiscount = false;
                                result.Provider.AIAVitalityFeeDiscount = true;
                            } else if (result.Provider.SpecialProviderSetting === 'VD') {
                                result.Provider.AIAVitalityDiscount = true;
                                result.Provider.AIAVitalityFeeDiscount = false;
                            } else {
                                result.Provider.AIAVitalityDiscount = false;
                                result.Provider.AIAVitalityFeeDiscount = false;
                            }
                            
                        }
                    }
                }
            }
            this.isLoadingData = false;
        });
    }


    close(): void {
        this.dialogRef.close(false);
    }

    doCrunch() {
        this.dialogRef.close(true);
    }

    getFrequencyName(): string {

        let frequency = 12;

        for (let p of this.data.QuoteEntity.PeopleEntity) {
            for (let b of p.BenefitList) {
                frequency = b.QuoteOption.Frequency;
                break;
            }
        }


        let value: string = 'Annual';

        switch (frequency) {
            case 1: value = 'Annual'; break;
            case 12: value = 'Monthly'; break;
            case 26: value = 'Fortnightly'; break;
            default: value = 'Annual'; break;
        }

        return value;
    }


    changeProduct(provider: Provider, benefit: Benefit) {
        // tslint:disable-next-line:prefer-const
        provider.IsSelected = true;
        let dialogRef = this.dialog.open(UserProviderSettingDialog, {
            data: { Provider: provider, Benefit: benefit, isQuoteResult:true },
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe(result => {

            // change from own product to own product
            if (result.needRemoveCrosProvider === false && result.updateOwnProduct === true) {
                this.isLoadingData = true;
                // Submit change to server side.
                this.quoteService.updateUserProviderSetting(provider, (response) => {
                    if (response && response.MessageCode === 200) {
                        // this.updateStatus = response;
                        this.isLoadingData = false;
                        this.showNeedReCrunchAlert = true;
                        this.sharedFunction.openSnackBar("Share-SUCCESS-Updated", 'Close');
                    }
                });
            } 
            
            // removed linked products
            if (result.needRemoveCrosProvider === true && result.updateOwnProduct === false) {
                this.isLoadingData = true;
                this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
                    if (response && response.MessageCode === 200) {
                        this.isLoadingData = false;
                        this.showNeedReCrunchAlert = true;
                        this.sharedFunction.openSnackBar("Share-SUCCESS-Updated", 'Close');
                    }
                    this.loadUserProviderSetting();
                });
            }

        });
    }


    changeProviderSpecialSetting(provider: Provider) {
        if (provider.ProviderId === 1) {
            let code = '';
            if (provider.AIAVitalityFeeDiscount === true && provider.AIAVitalityDiscount === true) {
                code = 'VFVD';
            } else if (provider.AIAVitalityFeeDiscount === true) {
                code = 'VF';
            } else if (provider.AIAVitalityDiscount === true) {
                code = 'VD';
            }

            // get current quote
            // shold save aia vitality into current quote.
            if (this.data && this.data.QuoteEntity) {
                let aiaSpecialQuoteSetting: SpecialQuoteSetting = null;
                if (!this.data.QuoteEntity.SpecialQuoteSettings) {
                    this.data.QuoteEntity.SpecialQuoteSettings = [];
                }

                // find special quote setting in current quote setting
                for (let sqs of this.data.QuoteEntity.SpecialQuoteSettings) {
                    if (sqs.ProviderId === provider.ProviderId) {
                        aiaSpecialQuoteSetting = sqs;
                        break;
                    }
                }

                if (aiaSpecialQuoteSetting === null) {
                    // add new special quote setting
                    aiaSpecialQuoteSetting = new SpecialQuoteSetting();
                    aiaSpecialQuoteSetting.ProviderId = provider.ProviderId;
                    aiaSpecialQuoteSetting.SpecialProviderSetting = code;
                    this.data.QuoteEntity.SpecialQuoteSettings.push(aiaSpecialQuoteSetting);
                } else {
                    // update it
                    aiaSpecialQuoteSetting.ProviderId = provider.ProviderId;
                    aiaSpecialQuoteSetting.SpecialProviderSetting = code;
                }
            }
            
        }

        this.showNeedReCrunchAlert = true;
    }

    selectedIndexChange(selectedIndex: number) {
        if (selectedIndex >= 0 && selectedIndex < this.allQuoteResults.length) {
            this.selectedProvider = this.allQuoteResults[selectedIndex].Provider;

            // add tracking event
            this.trackingEvent(this.selectedProvider);
        }
    }

    printQuoteReport(isPrintAll: boolean) {
        if (this.data.AppId > 0) {
            this.isLoadingData = true;
            // set selected provider
            let selectedProviderId: number | null = null;
            if (isPrintAll === false && this.selectedProvider && this.selectedProvider.ProviderId > 0) {
                selectedProviderId = this.selectedProvider.ProviderId;
            }

            // get current select provider
            this.quoteService.getQuoteReportByProvider(
                this.data.AppId,
                selectedProviderId,
                (response) => {
                    if (response) {
                       
                        this.dialog.open(QuoteReportDialogComponent, {
                            data: response,
                            maxHeight: "90%",
                            maxWidth: "1140px",
                            width: "80vw",
                            disableClose: true,
                            autoFocus: false,
                            restoreFocus: false,
                        });
                    }
                    this.isLoadingData = false;
                }
            );
        }
    }

    private trackingEvent(provider: Provider) {

        if (provider && provider.ProviderId > 0) {
            // add tracking event
            this.loginService.doGoogleTracking("quote-result-popup", "open-provider", "PID" + provider.ProviderId.toString());

            if (provider.ScrolloverBanner && provider.ScrolloverBanner.Description) {
                // add tracking event
                this.loginService.doGoogleTracking("quote-result-popup", "open-provider-with-scrollover", "PID" + provider.ProviderId.toString());
            }
        }
    }

}
