<div class='p-5'>
  <ul class='list-group'>
    <li class='list-group-item active'>
      <h5>CheckMonster</h5>
    </li>
    <!-- dropdown fields-->
    <li class='list-group-item'>
      <div class='row'>
        <!-- select benefit -->
        <div class='col-12 col-md-6'>
          <mat-form-field>
            <mat-select placeholder="Benefit" name='benefit' [(ngModel)]="selectedBenefit.Value" required
                        (selectionChange)='getCheckMonsterData()'>
              <mat-option *ngFor="let benefit of benefitList"
                          [value]="benefit.Value">{{benefit.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- select type of data -->
        <div class='col-12 col-md-6'>
          <mat-form-field>
            <mat-select placeholder="Type Of Data" name='typeOfData' [(ngModel)]="selectedTypeOfData.Value" required
                        (selectionChange)='getCheckMonsterData()'>
              <mat-option *ngFor="let typeOfData of typeOfDataList"
                          [value]="typeOfData.Value">{{typeOfData.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </li>

    <!-- MedianCoverAmountList Table -->
    <li class="list-group-item border-top-dashed" *ngIf="displayMedianCoverAmountList.length !== 0">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <tr>
            <th class='text-center'>
         
            </th>
            <ng-container *ngFor='let income of checkMonsterData.Incomes'>
              <th class='text-center'>
                {{income.Name}}
              </th>
            </ng-container>
          </tr>

          <tbody class='bg-white'>
            <ng-container *ngFor='let displayMca of displayMedianCoverAmountList'>
              <tr>
                <td class='align-middle'>
                  {{displayMca.HouseholderName}}
                </td>
                <ng-container *ngFor="let income of checkMonsterData.Incomes">
                  <td>
                    <ng-container *ngFor='let incomeMca of displayMca.IncomeMedianCoverAmounts'>
                      <ng-container *ngIf="incomeMca.IncomeId === income.Id">
                        <input class='form-control' [ngClass]="{'text-danger': incomeMca.hasChanged}" [(ngModel)]="incomeMca.CoverAmount"
                               currencyMask
                               [options]="{ allowNegative: false, prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                               (keyup)='incomeMca.hasChanged=true'
                               appAutoSelect>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <th class='text-center'>
              
              </th>
              <ng-container *ngFor='let income of checkMonsterData.Incomes'>
                <th class='text-center'>
                  {{income.Name}}
                </th>
              </ng-container>
            </tr>
          </tfoot>
        </table>
      </div>
    </li>
    <!-- PercentageOfSeekingList Table -->
    <li class="list-group-item border-top-dashed" *ngIf="displayPercentageOfSeekingList.length !== 0">
      <div class='table-responsive mt-3'>
        <table class='table table-bordered table-info table-hover'>
          <tr>
            <th class='text-center'>
              
            </th>
            <ng-container *ngFor='let income of checkMonsterData.Incomes'>
              <th class='text-center'>
                {{income.Name}}
              </th>
            </ng-container>
          </tr>

          <tbody class='bg-white'>
            <ng-container *ngFor='let displayPos of displayPercentageOfSeekingList'>
              <tr>
                <td class='align-middle'>
                  {{displayPos.HouseholderName}}
                </td>
                <ng-container *ngFor="let income of checkMonsterData.Incomes">
                  <td>
                    <ng-container *ngFor='let incomePos of displayPos.IncomePercentageOfSeekings'>
                      <ng-container *ngIf="incomePos.IncomeId === income.Id">
                        <div class='input-group'>
                          <input class='form-control' [ngClass]="{'text-danger': incomePos.hasChanged}"
                                 [(ngModel)]="incomePos.PercentageOfSeeking" currencyMask
                                 [options]="{ allowNegative: false , align : 'right', suffix: '%', prefix: '', precision: 1 }"
                                 (keyup)='incomePos.hasChanged=true'
                                 appAutoSelect>
                      
                          <!-- <div class="input-group-append">
                                                  <span class='input-group-text'>%</span>
                                                </div> -->
                        </div>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <th class='text-center'>
             
              </th>
              <ng-container *ngFor='let income of checkMonsterData.Incomes'>
                <th class='text-center'>
                  {{income.Name}}
                </th>
              </ng-container>
            </tr>
          </tfoot>
        </table>
      </div>
    </li>
    <!-- error message -->
    <li class="list-group-item border-top-dashed"
        *ngIf="errorMessage">
      <div class='alert alert-danger text-center' role='alert'>
        {{errorMessage}}
      </div>
    </li>
    <!-- loading image -->
    <li class="list-group-item border-top-dashed" *ngIf='isLoadingData'>
      <div class='row'>
        <div class='col text-center'>
          <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
          <h3>Loading data.</h3>
        </div>
      </div>
    </li>

    <!-- save button -->
    <li class='list-group-item border-top-dashed'
        *ngIf="displayMedianCoverAmountList.length !==0 || displayPercentageOfSeekingList.length !== 0">
      <div>
        <button mat-flat-button color="qm-pumpkin" class='w-100'
                (click)='saveCheckMonsterData();loginService.doGoogleTracking("InternalAdminPortal", "checkmonster", "save checkmonster data")'>
          Save
        </button>
      </div>
    </li>
  </ul>
</div>