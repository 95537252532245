import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { QuoteStepClient } from 'src/app/service/quote/quote.step.client';

@Component({
    selector: 'app-bulk-quote',
    templateUrl: './bulk-quote.component.html',
    styleUrls: ['./bulk-quote.component.scss'],
})
export class BulkQuoteComponent implements OnInit {
    @ViewChild('bulkQuoteTabGroup') bulkQuoteTabGroup: MatTabGroup;
    constructor( public quoteStepBenefit: QuoteStepBenefit, public quoteStepClient: QuoteStepClient,) {}

    ngOnInit(): void {}
    
    tabOnChange(e) {
        // this.quoteStepBenefit.loadUserProviderSetting(null)
        if (e.index === 2) {
            // if (this.quoteStepBenefit.errorMessage.length > 0) {
            //     this.quoteStepBenefit.validation();
            // }
            this.quoteStepBenefit.errorMessage =[]
        }
        if (e.index === 3) {
            // this.quoteStepBenefit.quoteDataList = this.quoteStepClient.quoteDataList;
            // this.quoteStepBenefit.callBulkCrunch();
        }
    
    }
    
    changeSelectedIndex(i) {
        this.bulkQuoteTabGroup.selectedIndex = i;
    }
}
