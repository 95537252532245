<li class='list-group-item border-top'>
  <div class='row'>
    <div class='col'>
      <mat-form-field>
        <mat-select placeholder='Frequency' [(ngModel)]='quoteStepBenefit.frequencyLoading'
          (selectionChange)='changeDefaultFreqnency()'>
          <mat-option *ngFor='let f of quoteStepBenefit.quoteRequiredData.FrequencyList' [value]='f.Value'>{{f.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class='col-md-6'>
      <mat-form-field>
        <mat-select placeholder='Factor' [(ngModel)]='quoteStepBenefit.factorLoading'>
          <mat-option [value]=0>100%</mat-option>
          <mat-option [value]=1>0%</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
</li>