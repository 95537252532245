import { Injectable } from '@angular/core';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { Qmads, QmadsList } from '../models/qmads';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';
import { DataLoaderService } from './data.loader.service';

@Injectable({
    providedIn: 'root',
})
export class QmadsService {

    constructor(
        private apiAddressService: ApiAddressService,
        public apiService: ApiService,
        private dataLoaderService: DataLoaderService,
    ) {
    }



    getQmadss(position: number, callback: (data: any) => void) {
        this.apiService.callApiWithoutAuth<any>(
            '', this.apiAddressService.getQmadsUrl(position),
            (response) => {
                callback(response);
            }
        );
    }



    // for Admin part
    getAdminQmadses(position: number, callback:(data: QmadsList) => void){
        this.apiService.callApi<QmadsList>(
            '', this.apiAddressService.getAdminQmAdsUrl(position),
            (response) => {
                callback(response);
            }
        )
    }

    // add or update qm ads.
  addOrUpdateAdminQmads(itemName: string, qmAdsItem: Qmads, file: File, callback: (data: {Item: Qmads, Message: GeneralResponseMessage}) => void) {
    this.dataLoaderService.uploadFileWithJsonFormData(
      itemName,
      qmAdsItem,
      file,
      this.apiAddressService.addOrUpdateAdminQmAdsUrl(),
      (response) => {
        callback(response);
      });
  }

}
