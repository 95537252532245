<!-- benefit selector start -->

  <div class='row' *ngFor='let benefitGroup of quoteStepBenefit.benefitGroup'>
    <div class='col-12'>
      <div class="alert alert-primary" role="alert">
        <strong>{{benefitGroup.GroupName}} </strong>
      </div>
    </div>
    <div class='col-sm-6 col-md-4 col-lg-3 py-1 ml-4' *ngFor='let benefit of benefitGroup.BenefitList'>
      <section>
        <mat-checkbox [(ngModel)]="benefit.IsSelected" [disabled]='benefit.IsDisabled' title='{{benefit.Title}}' (change)='quoteStepBenefit.selectBenefit(benefit)'>
          <span [ngClass]="{'text-muted': !benefit.IsSelected, 'text-blue': benefit.IsSelected}">
            {{benefit.BenefitName}}
          </span>
        </mat-checkbox>
      </section>
    </div>
  </div>


