<mat-tab-group dynamicHeight class='p-5 iap-tab-group' animationDuration='0ms' mat-align-tabs='center'
    [disableRipple]=true (selectedTabChange)="tabOnChange($event)" #bulkQuoteTabGroup>
    <mat-tab label='Product Settings'>
        <ng-template matTabContent>
            <quote-setting (changeSelectedIndex)='changeSelectedIndex(1)'></quote-setting>
        </ng-template>
    </mat-tab>
    <mat-tab label='Client Details'>
        <quote-client-component (changeSelectedIndex)='changeSelectedIndex(2)'></quote-client-component>
    </mat-tab>
    <mat-tab label='Benefit Details' [disabled]='false'>
        <quote-p-benefit (changeSelectedIndex)='changeSelectedIndex($event)'></quote-p-benefit>
    </mat-tab>
    <mat-tab label='Quote Results'>
        <ng-template matTabContent>
            <quote-p-benefit-result></quote-p-benefit-result>
        </ng-template>
    </mat-tab>
</mat-tab-group>