import { CurrentQuote } from '../models/current.quote';
import { LoginService } from './login.service';
import { Benefit } from '../models/benefit';
import { BenefitGroup } from '../models/benefit.group';
import { SelectItemStruct } from '../models/select.item';
import { SharedFunctionService } from './shared.function.service';
import { HttpClient } from '@angular/common/http';
import { ApiAddressService } from './api.address.service';
import { QuoteRequiredData } from '../models/quote.required.data';
import { DataLoader } from './data.loader';
import { PeopleEntity } from '../models/people';
import { ComponentBaseClass } from './base';
import { NeedAnalysisService } from './need.analysis.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from './api.service';
import { QprRequiredData } from '../models/qpr.models/qpr.required.data';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { Router } from '@angular/router';
import { BulkQuoteData } from '../models/bulk-quote-data.model';


export class QuoteBase extends ComponentBaseClass {


    public currentQuote: CurrentQuote = new CurrentQuote();
    public quoteRequiredData: QuoteRequiredData;
    public clientList: PeopleEntity[];
    public childList: PeopleEntity[];
    public loadingListForHealth: SelectItemStruct[];
    public loadingList: SelectItemStruct[];
    public healthExcessList: SelectItemStruct[];
    public waitPeriod: SelectItemStruct[];
    public benefitPeriod: SelectItemStruct[];
    public wopBenefitPeriod: SelectItemStruct[];
    public calcPeriodList: SelectItemStruct[];
    public clientAgeList: number[];
    public childAgeList: number[];
    public familyCoverBenefitPeriodAgeListTerm: number[];
    public familyCoverBenefitPeriodAgeListToAge: number[];
    public factor: number = 1;
    public errorMessage: string[] = [];
    public frequency: number = 12;

    public qprRequiredData: QprRequiredData = new QprRequiredData();

    constructor(
        public http: HttpClient,
        public apiAddressService: ApiAddressService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dataLoader: DataLoader,
        public needAnalysisService: NeedAnalysisService,
        public dialog: MatDialog,
        public apiService: ApiService,
        public confirmDialog: ConfirmMessageDialogService,
        public router: Router

    ) {
        super();


        this.loginService.checkLoginStatus();
        this.currentQuote = new CurrentQuote();

        this.quoteRequiredData = this.dataLoader.getRequiredData();
        this.loadingListForHealth = this.getLoadingList(true);
        this.loadingList = this.getLoadingList(false);
        this.healthExcessList = this.getHealthExcess();
        this.waitPeriod = this.getWaitPeriodList();
        this.benefitPeriod = this.getBenefitPeriodList();
        this.wopBenefitPeriod = this.getWopBenefitPeriodList();
        this.calcPeriodList = this.getCalcPeriodList();
    }


    // current quote
    saveCurrentQuote(currentQuote: CurrentQuote) {
        if (this.currentQuote !== null) {
            currentQuote.PreviousStep = this.currentQuote.CurrentStep;
        }
        this.currentQuote = currentQuote;
        window.sessionStorage.setItem('qmCurrentQuote', JSON.stringify(this.currentQuote));

    }

    getCurrentQuote(currentStep: any): CurrentQuote {
        try {
            this.currentQuote = JSON.parse(window.sessionStorage.getItem('qmCurrentQuote'));

            if (currentStep) {
                // save current step
                this.currentQuote.CurrentStep = currentStep;
                this.saveCurrentQuote(this.currentQuote);
            }


        } catch (e) {
            this.currentQuote = new CurrentQuote();
        }

        return this.currentQuote;
    }

    getBenefitGroup(): BenefitGroup[] {
        let benefitGroupList: BenefitGroup[] = [];

        let benefitGroup1 = new BenefitGroup('Health Cover', '');
        let benefitGroup2 = new BenefitGroup('Lump Sum Cover', '');
        let benefitGroup3 = new BenefitGroup('Repayment Cover', '');

        // add health
        benefitGroup1.BenefitList.push(
            new Benefit(1, 'Health Cover', 'Health')
        );
        // Life Cover
        benefitGroup2.BenefitList.push(
            new Benefit(2, 'Life Cover', 'Life')
        );
        // Family Protection
        benefitGroup2.BenefitList.push(
            new Benefit(3, 'Family Protection', 'Family')
        );
        // Trauma SA
        benefitGroup2.BenefitList.push(
            new Benefit(4, 'Trauma Standalone', 'Trauma Standalone')
        );


        // Trauma ACC
        benefitGroup2.BenefitList.push(
            new Benefit(14, 'Trauma Accelerated', 'Trauma Accelerated')
        );

        // Total and Permanent Disability
        benefitGroup2.BenefitList.push(
            new Benefit(5, 'TPD Standalone', 'TPD Standalone')
        );

        benefitGroup2.BenefitList.push(
            new Benefit(15, 'TPD Accelerated', 'TPD Accelerated')
        );


        // Income Protection (Annual)
        benefitGroup3.BenefitList.push(
            new Benefit(6, 'Income', 'IP')
        );
        // Mortgage Repayment Cover
        benefitGroup3.BenefitList.push(
            new Benefit(7, 'Mortgage Cover', 'MP')
        );

        // Redundancy Cover
        benefitGroup3.BenefitList.push(
            new Benefit(8, 'Redundancy', 'Redundancy')
        );

        // Waiver of Premium
        benefitGroup3.BenefitList.push(
            new Benefit(9, 'Waiver of Premium', 'WOP')
        );


        // Level Life
        benefitGroup2.BenefitList.push(
            new Benefit(11, 'Level Life', 'Level Life')
        );
        // Level Trauma
        benefitGroup2.BenefitList.push(
            new Benefit(12, 'Level Trauma', 'Level Trauma')
        );
        // Level TPD
        benefitGroup2.BenefitList.push(
            new Benefit(13, 'Level TPD', 'Level TPD')
        );
        // Specific Injury
        benefitGroup2.BenefitList.push(
            new Benefit(23, 'Specific Injury', 'Specific Injury')
        );



        benefitGroupList.push(benefitGroup1);
        benefitGroupList.push(benefitGroup2);
        benefitGroupList.push(benefitGroup3);

        return benefitGroupList;
    }

    getChildBenefitGroup(): BenefitGroup[] {
        let benefitGroupList: BenefitGroup[] = [];

        let benefitGroup1 = new BenefitGroup('Health Cover', '');
        let benefitGroup2 = new BenefitGroup('Lump Sum Cover', '');

        // add health
        benefitGroup1.BenefitList.push(
            new Benefit(1, 'Health Cover', 'Health')
        );
        // Trauma SA
        benefitGroup2.BenefitList.push(
            new Benefit(4, 'Trauma', 'Trauma')
        );

        benefitGroupList.push(benefitGroup1);
        benefitGroupList.push(benefitGroup2);

        return benefitGroupList;
    }



    getLoadingList(isForHealth: boolean = false): SelectItemStruct[] {
        let loadingList: SelectItemStruct[] = [];

        loadingList.push(
            new SelectItemStruct('0%', '', 1, true, 0, '', '', '', '', '', '')
        );

        if (isForHealth === true) {
            loadingList.push(
                new SelectItemStruct('25%', '', 1.25, false, 0, '', '', '', '', '', '')
            );
        }

        loadingList.push(
            new SelectItemStruct('50%', '', 1.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('75%', '', 1.75, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('100%', '', 2, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('125%', '', 2.25, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('150%', '', 2.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('175%', '', 2.75, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('200%', '', 3, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('250%', '', 3.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('300%', '', 4, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('400%', '', 5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('500%', '', 6, false, 0, '', '', '', '', '', '')
        );

        return loadingList;
    }

    getHealthExcess(): SelectItemStruct[] {
        let excessList: SelectItemStruct[] = [];

        excessList.push(
            new SelectItemStruct('Nil Excess', '', 0, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$250 Excess', '', 250, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$500 Excess', '', 500, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$1, 000 Excess', '', 1000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$2, 000 Excess', '', 2000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$3, 000 Excess', '', 3000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$4, 000 Excess', '', 4000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$5, 000 Excess', '', 5000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$6, 000 Excess', '', 6000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$10, 000 Excess', '', 10000, false, 0, '', '', '', '', '', '')
        );


        return excessList;
    }

    getWaitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('8 Weeks', '', 8, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('13 Weeks', '', 13, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('26 Weeks', '', 26, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('52 Weeks', '', 52, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('104 Weeks', '', 104, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getBenefitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('1 Year', '', 1, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('2 Years', '', 2, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('5 Years', '', 5, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 70', '', 70, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getWopBenefitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('To Age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 70', '', 70, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getCalcPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('10 Years', '', 10, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 70', '', 70, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 80', '', 80, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 85', '', 85, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 90', '', 90, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 100', '', 100, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getQprStarRating(value: number): string {
        let star: string = '';
        star = '<div class="qpr-star-rating"><span>';
        switch (value) {
            case 5: // 5 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                break;

            case 4.5: // 4.5 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_half</i>';
                break;


            case 4: // 4 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 3.5: // 3.5 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_half</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 3: // 3 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 2.5: // 2.5 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_half</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 2: // 2 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 1.5: // 1.5 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_half</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 1: // 1 star
                star += '<i class="material-icons">star</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 0.5: // 0.5 star
                star += '<i class="material-icons">star_half</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            case 0: // 0 star
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;

            default: // 0 star
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                star += '<i class="material-icons">star_border</i>';
                break;
        }

        star += '</span></div>';
        return star;
    }

    getBenefitName(benefit: Benefit, isUiID: boolean = false): string {
        let value: string = '';
        let selectedBenefitId: number = benefit.BenefitId;
        if (isUiID === true) {
            selectedBenefitId = benefit.UIBenefitId;
        }

        if (selectedBenefitId === 10) {
            selectedBenefitId = 6;
        } else if (selectedBenefitId === 4 || selectedBenefitId === 14 || selectedBenefitId === 12) {
            // check level trauma
            if (benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
                selectedBenefitId = 12;
            } else if (benefit.QuoteOption.Standalone !== true) {
                // check accelerated trauma
                selectedBenefitId = 14;
            }
        } else if (selectedBenefitId === 5 || selectedBenefitId === 15 || selectedBenefitId === 13) {
            if (benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
                // check level TPD
                selectedBenefitId = 13;
            } else if (benefit.QuoteOption.Standalone !== true) {
                // set accelerated tpd
                selectedBenefitId = 15;
            }
        } else if (selectedBenefitId === 2 && benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
            // set level life
            selectedBenefitId = 11;
        }

        let benefitGroups = this.getBenefitGroup();

        for (let g of benefitGroups) {
            for (let b of g.BenefitList) {
                if (isUiID === true && selectedBenefitId === b.UIBenefitId) {
                    value = b.BenefitName;
                    break;
                } else if (selectedBenefitId === b.BenefitId) {
                    value = b.BenefitName;
                    break;
                }

            }
            if (value !== '') {
                break;
            }
        }

        // value = value.replace('Level ', '');

        return value;
    }


    resetBenefitUIId(benefit: Benefit): Benefit {
        // set ui id
        benefit.UIBenefitId = benefit.BenefitId;


        if (benefit.UIBenefitId === 10) {
            benefit.UIBenefitId = 6;
        } else if (benefit.UIBenefitId === 4 || benefit.UIBenefitId === 14 || benefit.UIBenefitId === 12) {
            // check level trauma
            if (benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
                benefit.UIBenefitId = 12;
            } else if (benefit.QuoteOption.Standalone !== true) {
                // check accelerated trauma
                benefit.UIBenefitId = 14;
            }
        } else if (benefit.UIBenefitId === 5 || benefit.UIBenefitId === 15 || benefit.UIBenefitId === 13) {
            if (benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
                // check level TPD
                benefit.UIBenefitId = 13;
            } else if (benefit.QuoteOption.Standalone !== true) {
                // set accelerated tpd
                benefit.UIBenefitId = 15;
            }
        } else if (benefit.UIBenefitId === 2 && benefit.QuoteOption.CalcPeriod && benefit.QuoteOption.CalcPeriod.Value > 1) {
            // set level life
            benefit.UIBenefitId = 11;
        }

        return benefit;
    }

}
