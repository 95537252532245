<!-- loading data layout start -->
<div class="loading-screen text-center vh-100 d-flex align-items-center justify-content-center" *ngIf="loadingData">
  <div>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
  </div>
</div>
<!-- loading data layout end -->


<!-- after loading data layout start -->
<div class='d-flex flex-column justify-content-between vh-100 qm-internal-admin-portal' *ngIf='loadingData === false'>
  <nav-menu></nav-menu>
  <mat-card *ngIf="isDevMode" class='py-4 text-center rounded-0'>
    <h1 class='text-red'>NOTE: THIS IS QA SITE, FOR TESTING PURPOSES ONLY</h1>
    <a class='btn btn-link' href="https://qmadminportal.quotemonster.co.nz">Click here go to live Admin Portal</a>
  </mat-card>
  <div class='mb-auto flex-grow-1'>
    <router-outlet></router-outlet>
  </div>
  <footer class="footer p-5 qm-bg-darkGray">
    <h6 class="mb-0 text-white text-uppercase">
      Quotemonster Internal Admin Portal Site &nbsp;&nbsp; v.1.0.4
    </h6>
  </footer>
</div>

