import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Qmads } from 'src/app/models/qmads';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { DataLoader } from 'src/app/service/data.loader';
import { QmadsService } from 'src/app/service/qmads.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'add-banner-dialog',
  templateUrl: './add-banner-dialog.component.html',
  styleUrls: ['./add-banner-dialog.component.scss']
})
export class AddBannerDialogComponent implements OnInit {
  advertisingObj: Qmads = new Qmads();
  startDate: Date = null;
  endDate: Date = null;
  typeOfPositionList: QuoteDataObj[] = [];
  websitePrefix: string = 'https://';
  targetUrl: string = '';
  selectedFiles: FileList | null = null;
  previewImageUrl: SafeUrl = null;
  isNew: boolean = true;
  errorMessage: string = '';
  isLoadingData: boolean = false;
  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBannerDialogComponent>,
    public sharedFunction: SharedFunctionService,
    private dataLoader: DataLoader,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private qmadsService: QmadsService,
    @Inject(MAT_DIALOG_DATA) public data: Qmads
  ) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.advertisingObj = JSON.parse(JSON.stringify(this.data));;
      this.isNew = false;
      this.startDate = new Date(this.advertisingObj.StartDate);
      this.endDate = new Date(this.advertisingObj.EndDate);
      this.targetUrl = this.advertisingObj.TargetUrl.split('//')[1].split('/')[0];
      this.previewImageUrl = this.advertisingObj.FileLocation;
    } else {
      this.advertisingObj.Id = -1;
      this.advertisingObj.FileType = 1;
      this.isNew = true;
    }
    this.typeOfPositionList = this.dataLoader.getRequiredData().SkyBannerPositionList;
  }

  close(btnValue: boolean): void {
    if (btnValue) {
      this.updateAdvertising();
      this.isLoadingData = true;
      this.qmadsService.addOrUpdateAdminQmads("item", this.advertisingObj, this.selectedFiles && this.selectedFiles.length > 0 ? this.selectedFiles[0] : null, (response) => {
        if (response && response.Message.MessageCode === 200) {
          this.dialogRef.close(true);
        } else {
          this.errorMessage = response.Message.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        }
        this.isLoadingData = false;
      });
    } else {
      this.isLoadingData = false;
      this.dialogRef.close(false);
    }
  }

  selectFile(event: any): void {
    this.previewImageUrl = '';
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles.length > 0 && this.selectedFiles[0].type !== 'application/zip') {
      this.previewImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFiles[0]));
    }
  }

  revokeObjectURL(url: string) {
    URL.revokeObjectURL(url);
  }

  updateAdvertising(): void {
    this.advertisingObj.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.advertisingObj.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.advertisingObj.TargetUrl = this.websitePrefix + this.targetUrl;
    this.advertisingObj.FileLocation = this.previewImageUrl?.toString() || '';
  }
}
