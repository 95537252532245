import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { PeopleEntity } from '../../models/people';
import { QuoteStepClient } from '../../service/quote/quote.step.client';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { minSelectedCheckboxesValidation } from 'src/app/tools/form.validation';
import { BulkQuoteData } from 'src/app/models/bulk-quote-data.model';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';

interface Data {
    formControlName: string;
    value: string;
    displayName: string;
}

@Component({
    selector: 'quote-client-component',
    templateUrl: './quote-client.component.html',
    styleUrls: ['./quote-client.component.scss'],
})
export class QuoteClientComponent implements OnInit {
    @Output() changeSelectedIndex = new EventEmitter<void>();
    isGoNext: boolean = false;
    showDeleteMessage: boolean = false;
    accessRole: AccessRole = new AccessRole();
    callValidQuoteData: boolean = false;

    client = new PeopleEntity();

    clientForm: FormGroup;

    genderData: Data[] = [
        { formControlName: 'isMale', value: '1', displayName: 'Male' },
        { formControlName: 'isFemale', value: '2', displayName: 'Female' },
    ];
    smokeData: Data[] = [
        { formControlName: 'nonSmoker', value: '1', displayName: 'Non-Smoker' },
        { formControlName: 'smoker', value: '2', displayName: 'Smoker' },
    ];
    occupationData: Data[] = [
        { formControlName: 'isClass1', value: '1', displayName: 'Class 1' },
        { formControlName: 'isClass2', value: '2', displayName: 'Class 2' },
        { formControlName: 'isClass3', value: '3', displayName: 'Class 3' },
        { formControlName: 'isClass4', value: '4', displayName: 'Class 4' },
        { formControlName: 'isClass5', value: '5', displayName: 'Class 5' },
    ];

    numberOfChildrenData: number[] = [0, 1, 2, 3, 4, 5];
    ageFrom: number;
    ageTo: number;
    ageFromData: number[] = [];
    ageToData: number[] = [];
    ageIntervalData: number[] = [1, 5, 10];

    genderSelection: number[] = [];
    smokeSelection: number[] = [];
    ageSelection: number[] = [];
    occupationSelection: number[] = [];

    clientDataList: PeopleEntity[] = [];

    constructor(
        public quoteStepClient: QuoteStepClient,
        private quoteStepBenefit: QuoteStepBenefit,
        public dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router,
        public renderer2: Renderer2,
        public sharedFunction: SharedFunctionService,
        private clientFormBuilder: FormBuilder
    ) {}

    ngOnInit() {
        this.quoteStepClient.init();
        this.ageToData = this.ageFromData = this.quoteStepClient.clientAgeList;
        this.ageFrom = this.ageFromData[0];
        this.ageTo = this.ageToData[0];

        this.buildClientForm();
        this.onFormValueChanges();
    }

    buildClientForm() {
        this.clientForm = this.clientFormBuilder.group({
            quoteName:[''],
            firstName: [''],
            lastName: [''],
            gender: this.clientFormBuilder.group(
                {
                    isMale: [true],
                    isFemale: [false],
                },
                {
                    validators: minSelectedCheckboxesValidation(),
                }
            ),
            smoke: this.clientFormBuilder.group(
                {
                    nonSmoker: [true],
                    smoker: [false],
                },
                {
                    validators: minSelectedCheckboxesValidation(),
                }
            ),
            age: this.clientFormBuilder.group({
                ageFrom: [this.ageFrom],
                ageTo: [this.ageTo],
            }),
            ageInterval: [1],
            occupation: this.clientFormBuilder.group(
                {
                    isClass1: [true],
                    isClass2: [false],
                    isClass3: [false],
                    isClass4: [false],
                    isClass5: [false],
                },
                {
                    validators: minSelectedCheckboxesValidation(),
                }
            ),
            withPartner: [false],
            numberOfChildren: [this.numberOfChildrenData[0]],
        });
    }

    onSubmit() {
        this.updateCheckboxSelection('gender', this.genderSelection);
        this.updateCheckboxSelection('smoke', this.smokeSelection);
        this.updateCheckboxSelection('occupation', this.occupationSelection);
        this.updateAgeSelection();
        if (this.clientForm.valid) {
            this.quoteStepClient.clientForm = this.clientForm;
            this.createQuoteDataList();
            this.changeSelectedIndex.emit();
        }
    }

    onFormValueChanges() {
        this.clientForm.valueChanges
            // .pipe(
            //     debounceTime(1000), // Waiting for 1 sec while you are typing
            //     distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
            // )
            .subscribe((selectedValue) => {
                this.updateCheckboxSelection('gender', this.genderSelection);
                this.updateCheckboxSelection('smoke', this.smokeSelection);
                this.updateCheckboxSelection('occupation', this.occupationSelection);
                this.updateAgeSelection();
                if (this.clientForm.valid) {
                    this.quoteStepClient.clientForm = this.clientForm;
                    this.createQuoteDataList();
                } else {
                    this.quoteStepClient.clientForm = null;
                    this.quoteStepClient.quoteDataList = [];
                }
            });
    }

    updateCheckboxSelection(formGroupName: string, selection: number[]) {
        selection.splice(0, selection.length);
        let formGroup = this.clientForm.get(formGroupName) as FormGroup;
        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.controls[key];
            if (control.value === true) {
                selection.push(Number((<any>control).nativeElement.defaultValue));
            }
        });
    }

    updateAgeSelection() {
        let ageInterval: number = this.clientForm.get('ageInterval').value;
        let i = this.clientForm.get('age.ageFrom').value;
        this.ageTo = this.clientForm.get('age.ageTo').value;
        this.ageSelection = [];
        while (i < this.ageTo) {
            this.ageSelection.push(i);
            i += ageInterval;
        }
        this.ageSelection.push(this.ageTo);
    }

    createQuoteDataList() {
        this.quoteStepClient.quoteDataList = [];
        const quoteName = this.clientForm.get('quoteName').value;
        const firstName = this.clientForm.get('firstName').value;
        const lastName = this.clientForm.get('lastName').value;
        let count: number = 1;
        for (let i of this.genderSelection) {
            for (let j of this.smokeSelection) {
                for (let k of this.ageSelection) {
                    for (let l of this.occupationSelection) {
                        let quoteData = new BulkQuoteData();
                        quoteData.QuoteName = quoteName;
                        quoteData.AppId = count;
                        quoteData.PeopleEntity = [];

                        let client = new PeopleEntity();
                        if (count === 1) {
                            client.FirstName = firstName;
                        } else {
                            client.FirstName = `${firstName}-${count}`;
                        }
                        client.LastName = lastName;
                        client.ClientId = 0;
                        client.OccupationId.Value = l;
                        client.Gender = i;
                        if (j === 1) {
                            client.Smoker = false;
                        } else {
                            client.Smoker = true;
                        }
                        client.Age = k;
                        client.BenefitGroup = this.quoteStepClient.getBenefitGroup();
                        client.IsActive = true;
                        quoteData.PeopleEntity.push(client);

                        if (this.clientForm.get('withPartner').value) {
                            quoteData.PeopleEntity.push(this.addPartner(client));
                        }

                        if (this.clientForm.get('numberOfChildren').value > 0) {
                            for (let i = 0; i < this.clientForm.get('numberOfChildren').value; i++) {
                                quoteData.PeopleEntity.push(this.addChildren(client, i));
                            }
                            
                            this.quoteStepBenefit.hasChild = true;
                        } else {
                            this.quoteStepBenefit.hasChild = false;
                        }

                        this.quoteStepClient.quoteDataList.push(quoteData);
                        count++;
                    }
                }
            }
        }
    }

    addPartner(client: PeopleEntity): PeopleEntity {
        let partner = new PeopleEntity();
        partner = JSON.parse(JSON.stringify(client));
        partner.ClientId = 1;
        partner.FirstName = `${client.FirstName}-partner`;
        if (client.Gender === 1) {
            partner.Gender = 2;
        } else {
            partner.Gender = 1;
        }
        return partner;
    }

    addChildren(client: PeopleEntity, i: number): PeopleEntity {
        let child = new PeopleEntity();
        child.FirstName = `${client.FirstName}-child-${i+1}`;
        child.LastName = client.LastName;
        child.ClientId = i;
        child.Gender = 1;
        child.Age = 1;
        child.IsChild = true;
        child.BenefitGroup = this.quoteStepClient.getChildBenefitGroup();
        return child;
    }

    updateAgeToList() {
        this.ageFrom = this.clientForm.get('age.ageFrom').value;
        this.ageToData = [];
        for (let i = this.ageFrom; i < 76; i++) {
            this.ageToData.push(i);
        }
        if (this.clientForm.get('age.ageTo').value < this.ageFrom) {
            this.clientForm.get('age.ageTo').setValue(this.ageFrom);
        }
    }
}
