<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2" *ngIf="isNew">Add New Sky Banner</h4>
  <h4 class="my-2" *ngIf="!isNew">Edit Sky Banner (ID: {{advertisingObj.Id}})</h4>
</div>

<div mat-dialog-content class='px-5 py-2' #contentContainer>
  <!-- loading image -->
  <div class='row' *ngIf='isLoadingData'>
    <div class='col text-center'>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
    </div>
  </div>
  <!-- error message -->
  <ng-container *ngIf='!isLoadingData'>
    <div class='alert alert-danger text-center' *ngIf="errorMessage">
      <strong> {{errorMessage}}</strong>
    </div>
  </ng-container>

  <!-- edit screen -->
  <form #addAdvertisingForm='ngForm'>
    <ng-container *ngIf='!isLoadingData && !isConfirmScreen'>
      <div class='row'>
        <!-- banner type -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Type of Banner</mat-label>
            <mat-select placeholder="" name='typeOfBanner'
                        [(ngModel)]="advertisingObj.BannerType"
                        (selectionChange)='getBannersPositionList()'
                        required>
              <mat-option *ngFor="let bannerType of typeOfBannerList"
                          [value]='bannerType.Value'>{{bannerType.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- position -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Position</mat-label>
            <mat-select placeholder="" name='position'
                        [(ngModel)]="advertisingObj.Position" required>
              <mat-option *ngFor="let position of typeOfPositionList"
                          [value]='position.Position'>{{position.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- new advertising name -->
        <div class="col-12 mb-3">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput placeholder='' name='adsName' required
                   [(ngModel)]="advertisingObj.Name"
                   appAutoSelect appAutofocus>
            <mat-hint>This name will be shown on Google analytic</mat-hint>
            <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
          </mat-form-field>
        </div>

        <!-- new advertising start date -->
        <div class='col-12'>
          <mat-form-field (click)="startDatePicker.open();" appearance="fill" floatLabel="always">
            <mat-label>Start Date</mat-label>
            <input matInput placeholder='' name='startDate' #startDateInput='ngModel'
                   [matDatepicker]="startDatePicker"
                   [(ngModel)]='startDate' readonly required>
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"
                                   [disabled]="false"></mat-datepicker-toggle>
            <mat-datepicker touchUi #startDatePicker [disabled]="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- new advertising end date -->
        <div class='col-12'>
          <mat-form-field (click)="endDatePicker.open();" appearance="fill" floatLabel="always">
            <mat-label>End Date</mat-label>
            <input matInput placeholder='' name='endDate' #endDateInput='ngModel' [matDatepicker]="endDatePicker"
                   [(ngModel)]='endDate'
                   readonly required>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"
                                   [disabled]="false"></mat-datepicker-toggle>
            <mat-datepicker touchUi #endDatePicker [disabled]="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- new advertising is active -->
        <div class='col-12 mb-3 mx-3'>
          <mat-checkbox [(ngModel)]="advertisingObj.IsActive" name='isActive'><span>Active</span></mat-checkbox>
        </div>

        <!-- target url -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always" class='website-label'>
            <mat-label>Target Url</mat-label>
            <input matInput class='align-baseline' placeholder='' name='adsUrl' required
                   [(ngModel)]="targetUrl"
                   appAutoSelect>
            <span matPrefix>{{websitePrefix}}&nbsp;&nbsp;</span>
            <mat-error></mat-error>
          </mat-form-field>
        </div>

        <!-- file type -->
        <div class='col-12'>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>File Type</mat-label>
            <mat-select placeholder="" name='fileType'
                        [(ngModel)]="advertisingObj.FileType" required>
              <mat-option [value]='1'>Image</mat-option>
              <mat-option [value]='3'>Html</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- upload file -->
        <div class='row custom-file mx-3'>
          <input type="file" class="custom-file-input clickable" name="fileLocation"
                 accept=".png,.jpg,.jpeg,.svg,.zip"
                 (change)='selectFile($event)'>
          <label class="custom-file-label" [ngClass]="{'text-black-50': !selectedFiles}"
                 for="customFile">{{selectedFiles?selectedFiles[0]?.name:"Select image file (png, jpg, jpeg, svg) or
            zip
            file"}}</label>
        </div>
      </div>
    </ng-container>
  </form>

  <!-- confirm screen -->
  <div class="row" *ngIf="!isLoadingData && isConfirmScreen">
      <ng-template *ngTemplateOutlet="confirmScreen"></ng-template>
  </div>

  <!-- image file preview -->
  <div class='row' *ngIf="!isLoadingData">
    <div class='col-12' *ngIf="advertisingObj.FileType === 1 && previewImageUrl" [ngClass]="{'mt-4': !isConfirmScreen}">
      <h6 class='text-blue' *ngIf="previewImageUrl && isConfirmScreen">Image Preview:</h6>
      <img *ngIf="previewImageUrl" [src]="previewImageUrl" (load)="revokeObjectURL($event.target.src)">
    </div>
  </div>
  <!-- error message -->
  <ng-container *ngIf='!isLoadingData'>
    <div class='alert alert-danger text-center' *ngIf="errorMessage">
      <strong> {{errorMessage}}</strong>
    </div>
  </ng-container>
</div>

<!-- footer -->
<div mat-dialog-actions align="end">
  <ng-container *ngIf="!isConfirmScreen">
    <button mat-flat-button color="qm-black" (click)="close()">
      Close
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="openConfirmScreen()"
            [disabled]='!addAdvertisingForm.valid || (isNew && !selectedFiles)'>
      Save
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmScreen">
    <button mat-flat-button color="qm-black" (click)="isConfirmScreen = false">
      Back to Edit
    </button>
    <button mat-flat-button color="qm-blue" class="ml-3" (click)="save()">
      Confirm
    </button>
  </ng-container>
</div>

<ng-template #confirmScreen>
  <div class='col-12 mb-4'>
    <h4 *ngIf="isNew">The new sky banner you are going to create is:</h4>
    <h4 *ngIf="!isNew">The edited sky banner is going to be:</h4>
  </div>
  <!-- banner type -->
  <div class='col-12'>
    <h6 class='text-blue'>Type of Banner:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.BannerTypeName}}</div>
  </div>

  <!-- position -->
  <div class='col-12'>
    <h6 class='text-blue'>Position:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.PositionName}}</div>
  </div>

  <!-- new advertising name -->
  <div class='col-12'>
    <h6 class='text-blue'>Name:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.Name}}</div>
  </div>

  <!-- new advertising start date -->
  <div class='col-6'>
    <h6 class='text-blue'>Start Date:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.StartDate | date:'dd/MM/y'}}</div>
  </div>

  <!-- new advertising end date -->
  <div class='col-6'>
    <h6 class='text-blue'>End Date:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.EndDate | date:'dd/MM/y'}}</div>
  </div>

  <!-- target url -->
  <div class='col-12'>
    <h6 class='text-blue'>Target Url:</h6>
    <div class='alert alert-secondary'>{{advertisingObj.TargetUrl}}</div>
  </div>
</ng-template>