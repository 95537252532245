import { Injectable } from '@angular/core';
import { GeneralResponseMessage } from '../models/messages/general.response.message';
import { QmAds, QmAdsList } from '../models/admin-qmAds/qmAds';
import { ApiAddressService } from './api.address.service';
import { ApiService } from './api.service';
import { DataLoaderService } from './data.loader.service';
import { QmAdsPosition } from '../models/admin-qmAds/qmAds-position.model';

@Injectable({
  providedIn: 'root',
})
export class QmadsService {

  constructor (
    private apiAddressService: ApiAddressService,
    public apiService: ApiService,
    private dataLoaderService: DataLoaderService,
  ) {
  }



  getQmadss(position: number, callback: (data: any) => void) {
    this.apiService.callApiWithoutAuth<any>(
      '', this.apiAddressService.getQmadsUrl(position),
      (response) => {
        callback(response);
      }
    );
  }



  // for Admin part
  getAdminQmAdsPositions(bannerType: number, callback:(data: QmAdsPosition[]) => void){
      this.apiService.callApi<QmAdsPosition[]>(
          '', this.apiAddressService.getAdminQmAdsPositionsUrl(bannerType),
          (response) => {
              callback(response);
          }
      )
  }

  getAdminQmadses(position: number, year: number, callback: (data: QmAdsList) => void) {
    this.apiService.callApi<QmAdsList>(
      '', this.apiAddressService.getAdminQmAdsUrl(position, year),
      (response) => {
        callback(response);
      }
    );
  }

  // add or update qm ads.
  addOrUpdateAdminQmads(itemName: string, qmAdsItem: QmAds, file: File, callback: (data: { Item: QmAds, Message: GeneralResponseMessage; }) => void) {
    this.dataLoaderService.uploadFileWithJsonFormData(
      itemName,
      qmAdsItem,
      file,
      this.apiAddressService.addOrUpdateAdminQmAdsUrl(),
      (response) => {
        callback(response);
      });
  }

}
