
  <mat-accordion class='quote-p-benefit-list-expansion'>
    <ng-container *ngFor="let benefit of quoteStepBenefit.benefitSelection">
      <mat-expansion-panel [expanded]="benefit.UIBenefitId === quoteStepBenefit.lastBenefitId" class="mb-3">
        <mat-expansion-panel-header>
          <mat-panel-title class="mx-0 col-5 align-items-center">
            <h5 class="mb-0" id='benefitId{{benefit.BenefitId}}' #destinationRef>
              {{quoteStepBenefit.getBenefitName(benefit)}}
            </h5>
          </mat-panel-title>

          <mat-panel-description class="mx-0 px-0 col-7 align-items-center justify-content-between">
            {{quoteStepBenefit.getBenefitDetailHeader(benefit)}}
            <button mat-mini-fab color="qm-black" class='scale-60'
              (click)='quoteStepBenefit.removeBenefit(benefit)'>
              <span class="material-icons">clear</span>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class='row mt-3'>
          <div class='col-12'>
            <mat-checkbox class="client-benefit-card-options-cb" [(ngModel)]='benefit.IsShowOptional'>
              Advanced Options
            </mat-checkbox>
          </div>
        </div>

        <div class='row client-benefit-card-options-holder' *ngIf='benefit.IsShowOptional'>
          <div class='col-12'>
            <mat-form-field>
              <mat-select placeholder="Benefit Loading" [(ngModel)]="benefit.QuoteOption.Loading"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <!-- load health loging start -->
                <ng-container *ngIf='benefit.UIBenefitId === 1'>
                  <mat-option *ngFor="let loading of quoteStepBenefit.loadingListForHealth" [value]="loading">
                    {{ loading.Name }}
                  </mat-option>
                </ng-container>
                <!-- load health loging end -->
                <!-- load other loading start -->
                <ng-container *ngIf='benefit.UIBenefitId !== 1'>
                  <mat-option *ngFor="let loading of quoteStepBenefit.loadingList" [value]="loading">
                    {{ loading.Name }}
                  </mat-option>
                </ng-container>
                <!-- load other loading end -->

              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- health cover option -->
        <div class='row' *ngIf='benefit.UIBenefitId === 1'>
          <div class='col-12'>
            <mat-form-field>
              <mat-select placeholder="Select Excess" [(ngModel)]="benefit.QuoteOption.Excess"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let excess of quoteStepBenefit.healthExcessList" [value]="excess">{{
                  excess.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.SpecialistsTest'>
              Specialists & Test
            </mat-checkbox>
          </div>
          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.GpPrescriptions'>
              GP & Prescriptions
            </mat-checkbox>
          </div>
          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.DentalOptical'>
              Dental & Optical
            </mat-checkbox>
          </div>
        </div>
        <!-- health cover option end -->
        <!-- life cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 2'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.FutureInsurability'>
              Future
              Insurability
            </mat-checkbox>
          </div>
          <div class='col-12'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
        </div>
        <!-- life cover end -->
        <!-- family cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 3'>
          <div class='col-12'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount Monthly" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Benefit Period" [(ngModel)]="benefit.QuoteOption.BenefitPeriodTerm">
                <mat-option [value]="1">Fixed Term</mat-option>
                <mat-option [value]="2">To Age</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='benefit.QuoteOption.BenefitPeriodTerm === 1'>
            <mat-form-field>
              <mat-select placeholder="Fixed Term" [(ngModel)]="benefit.QuoteOption.BenefitPeriodYear">
                <mat-option *ngFor="let term of quoteStepBenefit.familyCoverBenefitPeriodAgeListTerm" [value]="term">{{
                  term }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col' *ngIf='benefit.QuoteOption.BenefitPeriodTerm === 2'>
            <mat-form-field>
              <mat-select placeholder="To Age" [(ngModel)]="benefit.QuoteOption.BenefitPeriodYear">
                <mat-option *ngFor="let age of quoteStepBenefit.familyCoverBenefitPeriodAgeListToAge" [value]="age">{{
                  age }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- family cover end -->
        <!-- trauma SA cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 4'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>

          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack' [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>Trauma
              Buyback
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback' [disabled]='benefit.QuoteOption.TraumaBuyBack'>Instant Trauma Buyback
                <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                        (mousedown)="$event.stopPropagation()"
                        (click)='$event.preventDefault();sharedFunction.openSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback", "Close", 10000)'
                        [matTooltip]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitList-INFO-InstantTraumaBuyback')">help_outline
                </span>
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
              TPD
              Add-on
            </mat-checkbox>
            <mat-form-field *ngIf='benefit.QuoteOption.TpdAddOn'>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class='col-12'></div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>

          <div class='col-12'></div>
          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.IsSeriousTrauma'>
              Serious Trauma
            </mat-checkbox>
            <button mat-icon-button color="qm-blue" class="align-bottom"
              (click)="sharedFunction.openSnackBar('QuotePersonalBenefitList-INFO-SeriousTrauma', 'Close', 20000)"
              matTooltip="This sum insured will be the total as a split benefit.">
              <span class="material-icons" aria-label="Config">help_outline</span>
            </button>
          </div>

          <div class='col' *ngIf='benefit.QuoteOption.IsSeriousTrauma '>
            <mat-form-field>
              <input matInput placeholder="Serious Trauma Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                [(ngModel)]="benefit.QuoteOption.SeriousTraumaCoverAmount" required appAutoSelect>
            </mat-form-field>
          </div>

        </div>
        <!-- trauma SA cover end -->

        <!-- trauma ACC cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 14'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
              Buyback
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack' [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>Trauma
              Buyback
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback' [disabled]='benefit.QuoteOption.TraumaBuyBack'>Instant Trauma Buyback
                <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                        (mousedown)="$event.stopPropagation()"
                        (click)='$event.preventDefault();sharedFunction.openSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback", "Close", 10000)'
                        [matTooltip]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitList-INFO-InstantTraumaBuyback')">help_outline
                </span>
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
              TPD
              Add-on
            </mat-checkbox>
            <mat-form-field *ngIf='benefit.QuoteOption.TpdAddOn'>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class='col-12'></div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>


          <div class='col-12'></div>
          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.IsSeriousTrauma'>
              Serious Trauma
            </mat-checkbox>
            <button mat-icon-button color="qm-blue" class="align-bottom"
              (click)="sharedFunction.openSnackBar('QuotePersonalBenefitList-INFO-SeriousTrauma', 'Close', 20000)"
              matTooltip="This sum insured will be deducted from the total as a split benefit.">
              <span class="material-icons" aria-label="Config">help_outline</span>
            </button>
          </div>

          <div class='col' *ngIf='benefit.QuoteOption.IsSeriousTrauma'>
            <mat-form-field>
              <input matInput placeholder="Serious Trauma Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                [(ngModel)]="benefit.QuoteOption.SeriousTraumaCoverAmount" required appAutoSelect>
            </mat-form-field>
          </div>
        </div>
        <!-- trauma ACC cover end -->


        <!-- tpd std cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 5'>
          <div class='col-12' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed
            </mat-checkbox>
          </div>

          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- tpd std cover end -->

        <!-- tpd acc cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 15'>
          <ng-container *ngIf='benefit.IsShowOptional'>
            <div class='col'>
              <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
                Indexed
              </mat-checkbox>
            </div>
            <div class='col'>
              <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
                Buyback
              </mat-checkbox>
            </div>
            <div class='col-12'>
              &nbsp;
            </div>
          </ng-container>

          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- tpd acc cover end -->

        <!-- ip cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 6'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Booster'>
              Booster</mat-checkbox>
          </div>
          <div class='col-12'></div>

          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Monthly Benefit Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)"
                [(ngModel)]="benefit.QuoteOption.IpMonthlyCoverAmount" required appAutoSelect>
            </mat-form-field>
          </div>

          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                (selectionChange)='quoteStepBenefit.onIpBenefitWeekWaitPeriodChange(benefit);'
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let wait of quoteStepBenefit.waitPeriod" [value]='wait'>{{wait.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Benefit Period" [(ngModel)]="benefit.QuoteOption.BenefitPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor='let period of quoteStepBenefit.benefitPeriod' [value]='period'>{{period.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Policy Type" [(ngModel)]="benefit.QuoteOption.IsTaxable">
                <mat-option [value]=2>Taxable</mat-option>
                <mat-option [value]=1>Non-Taxable</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- ip cover end -->
        <!-- mp cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 7'>
          <div class='col-12' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Monthly Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let wait of quoteStepBenefit.waitPeriod" [value]='wait'>{{wait.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Benefit Period" [(ngModel)]="benefit.QuoteOption.BenefitPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor='let period of quoteStepBenefit.benefitPeriod' [value]='period'>{{period.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- mp cover end -->
        <!-- redundancy cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 8'>
          <div class='col-12' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>

          <div class='col-12'>
            <mat-form-field>
              <input matInput placeholder="Monthly Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
        </div>
        <!-- redundancy cover end -->
        <!-- wop cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 9'>
          <div class='col-12'>
            <mat-form-field>
              <mat-select placeholder="Week Wait Period" [(ngModel)]="benefit.QuoteOption.WeekWaitPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let wait of quoteStepBenefit.waitPeriod" [value]='wait'>{{wait.Name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- wop cover end -->
        <!-- level life cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 11'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.FutureInsurability'>
              Future
              Insurability
            </mat-checkbox>
          </div>
          <div class='col-12'></div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Cal Period" [(ngModel)]="benefit.QuoteOption.CalcPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let period of quoteStepBenefit.calcPeriodList" [value]='period'>{{period.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- level life cover end -->
        <!-- level trauma cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 12'>
          <div class='col'
            *ngIf='benefit.IsShowOptional && quoteStepBenefit.checkLifeCover() && benefit.QuoteOption.Standalone === false'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.LifeBuyBack'>Life
              Buyback
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TraumaBuyBack' [disabled]='benefit.QuoteOption.InstantTraumaBuyback'>Trauma
              Buyback
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.InstantTraumaBuyback' [disabled]='benefit.QuoteOption.TraumaBuyBack'>Instant Trauma Buyback
                <span class="material-icons scale-80 align-bottom text-blue" aria-label="help"
                        (mousedown)="$event.stopPropagation()"
                        (click)='$event.preventDefault();sharedFunction.openSnackBar("QuotePersonalBenefitList-INFO-InstantTraumaBuyback", "Close", 10000)'
                        [matTooltip]="sharedFunction.getUiMessageByCode('QuotePersonalBenefitList-INFO-InstantTraumaBuyback')">help_outline
                </span>
            </mat-checkbox>
          </div>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.TpdAddOn'>
              TPD
              Add-on
            </mat-checkbox>
            <mat-form-field *ngIf='benefit.QuoteOption.TpdAddOn'>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.TpdOwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'
            *ngIf='benefit.IsShowOptional && quoteStepBenefit.hasChild'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.ChildTrauma'>Child's
              Trauma
            </mat-checkbox>
            <mat-form-field *ngIf='benefit.QuoteOption.ChildTrauma'>
              <input matInput placeholder="Child Trauma Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0, align : 'left' }"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.ChildCoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col-12'></div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Cover Type" [(ngModel)]="benefit.QuoteOption.Standalone"
                [disabled]='!quoteStepBenefit.checkLifeCover()'>
                <mat-option [value]=true>Standalone</mat-option>
                <mat-option [value]=false>Accelerated</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Cal Period" [(ngModel)]="benefit.QuoteOption.CalcPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let period of quoteStepBenefit.calcPeriodList" [value]='period'>{{period.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- level trauma cover end -->
        <!-- level tpd cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 13'>

          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required appAutoSelect>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Occupation" [(ngModel)]="benefit.QuoteOption.OwnOccupation">
                <mat-option [value]=true>Own Occupation</mat-option>
                <mat-option [value]=false>Any Occupation</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Cover Type" [(ngModel)]="benefit.QuoteOption.Standalone"
                [disabled]='!quoteStepBenefit.checkLifeCover()'>
                <mat-option [value]=true>Standalone</mat-option>
                <mat-option [value]=false>Accelerated</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='col'>
            <mat-form-field>
              <mat-select placeholder="Cal Period" [(ngModel)]="benefit.QuoteOption.CalcPeriod"
                [compareWith]='quoteStepBenefit.setDefaultInsurerValue'>
                <mat-option *ngFor="let period of quoteStepBenefit.calcPeriodList" [value]='period'>{{period.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- level tpd cover end -->

        <!-- specific Injury cover -->
        <div class='row' *ngIf='benefit.UIBenefitId === 23'>
          <div class='col' *ngIf='benefit.IsShowOptional'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.Indexed'>
              Indexed</mat-checkbox>
          </div>
          <div class='col-12'></div>
          <div class='col'>
            <mat-form-field>
              <input matInput placeholder="Monthly Cover Amount" currencyMask
                [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'left'}"
                (keyup)="checkBenefitRecommendCoverAmount(benefit)" [(ngModel)]="benefit.QuoteOption.CoverAmount"
                required />
            </mat-form-field>
          </div>
          <div class='col-12'></div>
          <div class='col'>
            <mat-checkbox [(ngModel)]='benefit.QuoteOption.ExtData' (change)='setExtDataInJson(benefit.QuoteOption)'>
              Include Partners Life Specific Conditions
            </mat-checkbox>
          </div>
        </div>
        <!-- specific Injury cover end -->
      </mat-expansion-panel>
    </ng-container>

  </mat-accordion>


<!-- list benefit end -->
