<ul class="list-group border-none">
  <!-- product settings -->
  <li class='list-group-item'>
    <div class='table-responsive setting-quote-table'>
      <table class="table">
        <thead>
          <tr class='table-info-thead'>
            <div class='row flex-nowrap mx-0'>
              <div class='col-2 py-2 px-2'>
                <strong>Select Provider</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Health</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Trauma</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>IP Taxable</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>IP Non Taxable</strong>
              </div>
              <div class='col-2 py-2 px-4'>
                <strong>Mortgage</strong>
              </div>
            </div>
          </tr>
        </thead>
        <tbody>
          <tr class='border-bottom' *ngFor="let provider of userProviderSetting">
            <div class='row flex-nowrap mx-0'>
              <div class='col-2 py-2 px-2'>
                <mat-checkbox [(ngModel)]="provider.IsSelected" (change)="changeProviderSetting(provider)"
                  class="ml-1 provider-checkbox">
                  <img src="{{provider.ProviderLogoUrl}}?t={{timestamp}}" class='pl-3' />
                </mat-checkbox>
              </div>

              <ng-container *ngIf='provider.IsSelected'>
                <!--Health-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 1'>
                      <ng-container
                        *ngIf='benefit.ProductEntityList && benefit.ProductEntityList.length > 0'>
                        <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                          (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                      </ng-container>
                    </ng-container>
                  </ng-container>


                </div>

                <!--Trauma-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 4  '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--IP taxable-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 6   '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--IP non-taxable-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 10   '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>

                <!--MP-->
                <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 7  '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf='!provider.IsSelected'>
                <!--Health-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--Trauma-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--IP taxable-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--IP non-taxable-->
                <div class='col-2 py-2 px-2'>
                </div>

                <!--MP-->
                <div class='col-2 py-2 px-2'>
                </div>
              </ng-container>
            </div>

            <div class='row flex-nowrap mx-0' *ngIf='provider.ProviderId === 1 && provider.IsSelected '>
              <div class='col-2 pt-2 px-2'>
              </div>
              <div class='col-10 pt-2 pl-4 pr-2 border-top'>
                <span class="align-text-bottom mr-4">AIA Vitality Discount
                  Type:</span>
                <mat-checkbox class="mr-4" (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="provider.AIAVitalityFeeDiscount">
                  AIA Vitality Fee
                </mat-checkbox>
                <mat-checkbox (change)="changeProviderSpecialSetting(provider)"
                  [(ngModel)]="provider.AIAVitalityDiscount">
                  AIA Vitality Discount
                </mat-checkbox>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </li>
  <!-- reset to default button -->
  <div>
    <button mat-flat-button color="warn" class='float-left' (click)='showResetAlert = !showResetAlert'
      title='click here to reset your product settings to default'>Reset to default</button>
    <button mat-flat-button color="qm-pumpkin" class='float-right' (click)='changeSelectedIndex.emit()'
      title='next'>Next</button>
  </div>
  <!-- reset to default alert -->
  <div class='row alert alert-warning justify-content-center align-items-center px-3 mt-2' *ngIf='showResetAlert'>
    <div class='col-auto text-center my-2'>
      <strong>{{sharedFunction.getUiMessageByCode("Share-WARNING-ResetToDefault")}}</strong>
    </div>
    <div class='col-auto my-2 py-1'>
      <button mat-raised-button class='mr-2' color="warn"
        (click)='showResetAlert=false;resetProviderSetting();loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>YES</button>

      <button mat-raised-button color="qm-black"
        (click)='showResetAlert=false;loginService.doGoogleTracking("provider-product-setting", "saveProviderSetting", "");'>NO</button>
    </div>
  </div>
</ul>