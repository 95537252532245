import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuoteService } from '../../service/quote.service';
import { LoginService } from '../../service/login.service';
import { Provider } from '../../models/provider';
import { Benefit } from '../../models/benefit';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentBaseClass } from '../../service/base';
import { UserProviderSettingDialog } from './quote-setting-dialog';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-setting',
    templateUrl: './quote-setting.component.html',
    styleUrls: ['./quote-setting.component.scss'],
})
export class QuoteSettingComponent extends ComponentBaseClass implements OnInit {
    @Output() changeSelectedIndex = new EventEmitter<void>();
    userProviderSetting: Provider[];
    updateStatus: string;
    showResetAlert: boolean = false;
    showHelpInfo: boolean = false;

    frequencyLoading: number = 12;
    timestamp: number = 123456789;

    displayBankInQuote: number = 1;

    constructor(
        public loginService: LoginService,
        public quoteService: QuoteService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService,
        private quoteStepBenefitService: QuoteStepBenefit
    ) {
        super();
    }

    ngOnInit() {
        this.loginService.checkLoginStatus();
        this.loadUserProviderSetting(null);
        this.timestamp = new Date().getTime();
    }

    loadUserProviderSetting(onFinish: () => void) {
        this.showDinoLoading();
        this.quoteService.getUserProviderSetting((response) => {
            if (response) {
                this.userProviderSetting = response;

                for (let p of this.userProviderSetting) {
                    if (p.ProviderId === 1) {
                        if (p.SpecialProviderDiscountCode === '') {
                            p.AIAVitalityDiscount = false;
                            p.AIAVitalityFeeDiscount = false;
                        } else if (p.SpecialProviderDiscountCode === 'VFVD') {
                            p.AIAVitalityDiscount = true;
                            p.AIAVitalityFeeDiscount = true;
                        } else if (p.SpecialProviderDiscountCode === 'VF') {
                            p.AIAVitalityDiscount = false;
                            p.AIAVitalityFeeDiscount = true;
                        } else if (p.SpecialProviderDiscountCode === 'VD') {
                            p.AIAVitalityDiscount = true;
                            p.AIAVitalityFeeDiscount = false;
                        } else {
                            p.AIAVitalityDiscount = false;
                            p.AIAVitalityFeeDiscount = false;
                        }
                        break;
                    }
                }

                // load default user qm setting
                this.quoteService.getUserQmSetting((response2) => {
                    if (response2 && response2.QmSetting) {
                        if (response2.QmSetting.DEF_FREQ) this.frequencyLoading = Number(response2.QmSetting.DEF_FREQ);

                        if (response2.QmSetting.DEF_DISPLAY_BANK_IN_QUOTE)
                            this.displayBankInQuote = Number(response2.QmSetting.DEF_DISPLAY_BANK_IN_QUOTE);
                    }

                    this.closeDinoLoading();

                    if (onFinish !== null) {
                        onFinish();
                    }
                });
            }
        });
    }

    changeDefaultFreqnency() {
        this.showDinoLoading();
        let newSetting: any = { DEF_FREQ: this.frequencyLoading.toString() };

        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                this.frequencyLoading = Number(response.QmSetting.DEF_FREQ);
                this.sharedFunction.openSnackBar('QuoteSetting-SUCCESS-ChangeDefaultFrequency', 'Close');
            }

            this.closeDinoLoading();
        });
    }

    changeDisplayBankInQuote() {
        this.showDinoLoading();
        let newSetting: any = { DEF_DISPLAY_BANK_IN_QUOTE: this.displayBankInQuote.toString() };

        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_DISPLAY_BANK_IN_QUOTE) {
                this.displayBankInQuote = Number(response.QmSetting.DEF_DISPLAY_BANK_IN_QUOTE);
                this.sharedFunction.openSnackBar('QuoteSetting-SUCCESS-ChangeDefaultDisplayEstimatePremiums', 'Close');
            }
            this.closeDinoLoading();
        });
    }

    changeProviderSetting(provider: Provider) {
        this.showDinoLoading();
        this.quoteService.updateUserProviderSetting(provider, (response) => {
            if (response) {
                this.loadUserProviderSetting(() => this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close'));
            }
        });
    }

    changeProduct(provider: Provider, benefit: Benefit) {
        let dialogRef = this.dialog.open(UserProviderSettingDialog, {
            data: {
                Provider: provider,
                Benefit: benefit,
                ProviderList: this.userProviderSetting,
            },
            disableClose: true,
            maxWidth: '85%',
            maxHeight: '90%',
            width: '400px',
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
            // change from linked product to own product
            if (result.needRemoveCrosProvider === true && result.updateOwnProduct === true) {
                this.showDinoLoading();
                this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
                    if (response && response.MessageCode === 200) {
                        this.quoteService.updateUserProviderSetting(provider, (response) => {
                            if (response) {
                                this.updateStatus = response.Message;
                                this.loadUserProviderSetting(() =>
                                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close')
                                );
                            }
                        });
                    }
                });
            }

            // change from own product to own product
            if (result.needRemoveCrosProvider === false && result.updateOwnProduct === true) {
                this.showDinoLoading();
                this.quoteService.updateUserProviderSetting(provider, (response) => {
                    if (response) {
                        this.updateStatus = response.Message;
                        this.loadUserProviderSetting(() =>
                            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close')
                        );
                    }
                });
            }

            // remove all linked products
            if (result.needRemoveCrosProvider === true && result.updateOwnProduct === false) {
                this.showDinoLoading();
                this.quoteService.removeUserCrossProviderSetting(result.ProviderId, result.BenefitId, (response) => {
                    if (response && response.MessageCode === 200) {
                        this.loadUserProviderSetting(() =>
                            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close')
                        );
                    }
                });
            }
        });
    }

    // resetProviderSetting() {
    //     this.showDinoLoading();

    //     for (let provider of this.userProviderSetting) {
    //         if (provider.ProviderId === 1) {
    //             provider.AIAVitalityFeeDiscount === false;
    //             provider.AIAVitalityDiscount === false;
    //             provider.SpecialProviderDiscountCode = '';
    //             this.quoteService.updateUserProviderSpecialSetting(provider.ProviderId, '', (response) => {
    //                 this.quoteService.resetUserProviderSetting((response) => {
    //                     this.loadUserProviderSetting(() => {
    //                         this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
    //                     });
    //                 });
    //             });
    //         }
    //     }
    // }

    resetProviderSetting() {
        this.showDinoLoading();
        this.resetProviderSpecialSetting();
        this.quoteService.resetUserProviderSetting((response) => {
            this.loadUserProviderSetting(() => {
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close')
            });
        });
    }

    changeProviderSpecialSetting(provider: Provider) {
        if (provider.ProviderId === 1) {
            let code = '';
            if (provider.AIAVitalityFeeDiscount === true && provider.AIAVitalityDiscount === true) {
                code = 'VFVD';
            } else if (provider.AIAVitalityFeeDiscount === true) {
                code = 'VF';
            } else if (provider.AIAVitalityDiscount === true) {
                code = 'VD';
            }

            provider.SpecialProviderDiscountCode = code;

            this.quoteService.updateUserProviderSpecialSetting(provider.ProviderId, code, (response) => {
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
            });
        }
    }

    resetProviderSpecialSetting() {
        for (let provider of this.userProviderSetting) {
            if (provider.ProviderId === 1) {
                provider.AIAVitalityFeeDiscount === false;
                provider.AIAVitalityDiscount === false;
                provider.SpecialProviderDiscountCode = '';
                this.quoteService.updateUserProviderSpecialSetting(provider.ProviderId, '', (response) => {});
            }
        }
    }

    hasBenefit(benefitList: Benefit[], benefitId: number): boolean {
        let value: boolean = false;

        if (benefitList) {
            for (let benefit of benefitList) {
                if (benefit.BenefitId === benefitId) {
                    value = true;
                    break;
                }
            }
        }

        return value;
    }

    getHealthBenefit(selectedProvider: Provider): Benefit {
        let healthBenefit: Benefit = new Benefit(1, 'Health', 'Health');
        healthBenefit.ShowInSettingColumn = 1;
        healthBenefit.ProductEntityList = [];
        return healthBenefit;
    }
}


