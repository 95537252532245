<form [formGroup]='clientForm' (submit)="onSubmit()">
  <ul class='list-group list-group-flush bulk-quote-client-container'>

    <li class='list-group-item'>
      <div class='row'>
        <mat-form-field class='col-12'>
          <input matInput placeholder='Quote Name' appAutofocus formControlName='quoteName' required appAutoSelect>
          <mat-error>Please enter quote name.</mat-error>
        </mat-form-field>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row'>
        <mat-form-field class='col-md-6'>
          <input matInput placeholder='First name' appAutofocus formControlName='firstName' required appAutoSelect>
          <mat-error>Please enter first name.</mat-error>
        </mat-form-field>

        <mat-form-field class='col-md-6'>
          <input matInput placeholder='Last Name' formControlName='lastName'
            required appAutoSelect>
          <mat-error>Please enter last name.</mat-error>
        </mat-form-field>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row' formGroupName='gender'>
        <h6 class='col-12 text-black'>Gender</h6>
        <ng-container *ngFor='let item of genderData'>
          <mat-checkbox class='col-md-3' [formControlName]='item.formControlName' [value]='item.value'>
            {{item.displayName}}
          </mat-checkbox>
        </ng-container>
        <mat-error class="col-12 mat-error qm-text-xsm" *ngIf='clientForm.get("gender").invalid'>
          Please select one of the options.
        </mat-error>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row' formGroupName='smoke'>
        <h6 class='col-12 text-black'>Smoke Condition</h6>
        <ng-container *ngFor='let item of smokeData'>
          <mat-checkbox class='col-md-3' [formControlName]='item.formControlName' [value]='item.value'>
            {{item.displayName}}
          </mat-checkbox>
        </ng-container>
        <mat-error class="col-12 mat-error qm-text-xsm" *ngIf='clientForm.get("smoke").invalid'>
          Please select one of options.
        </mat-error>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row' formGroupName='age'>
        <mat-form-field class='col-md-6'>
          <mat-select formControlName='ageFrom' placeholder='Age From' required (selectionChange)="updateAgeToList()">
            <mat-option *ngFor='let age of ageFromData' [value]='age'>
              {{ age }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class='col-md-6'>
          <mat-select formControlName='ageTo' placeholder='Age To' required>
            <mat-option *ngFor='let age of ageToData' [value]='age'>
              {{ age }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row'>
        <h6 class='col-12 text-black'>Age Interval</h6>
        <mat-radio-group formControlName='ageInterval' class='col-12'>
          <ng-container *ngFor='let i of ageIntervalData'>
            <mat-radio-button [value]='i' class='mr-4'>{{i}}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <div class='row justify-content-md-between' formGroupName='occupation'>
        <h6 class='col-12 text-black'>Occupation</h6>
        <ng-container *ngFor='let item of occupationData'>
          <mat-checkbox class='col-auto' [formControlName]='item.formControlName' [value]='item.value'>
            {{item.displayName}}
          </mat-checkbox>
        </ng-container>
        <mat-error class="col-12 mat-error qm-text-xsm" *ngIf='clientForm.get("occupation").invalid'>
          Please select one of the options.
        </mat-error>
      </div>
    </li>

    <li class='list-group-item border-top-dashed'>
      <mat-checkbox formControlName='withPartner'>With Partner</mat-checkbox>
    </li>

    <li class='list-group-item border-top-dashed'>
      <mat-form-field>
        <mat-select formControlName='numberOfChildren' placeholder='Number of Children'>
          <mat-option *ngFor='let number of numberOfChildrenData' [value]='number'>
            {{ number }}
          </mat-option>
        </mat-select>
        <mat-hint>All children are: 1 year old, Male</mat-hint>
      </mat-form-field>
    </li>

    <div>
      <button mat-flat-button color="qm-pumpkin" class='btn-block mt-4' type='submit'
        [disabled]='clientForm.invalid'>Next</button>
    </div>
  </ul>
</form>