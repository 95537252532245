import { Component, OnInit } from '@angular/core';
import { QuoteStepBenefit } from 'src/app/service/quote/quote.step.benefit';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { QuoteReportDialogComponent } from './quote-report-dialog.component';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteService } from 'src/app/service/quote.service';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-p-benefit-setting',
    templateUrl: './quote-p-benefit-setting.html',
    styleUrls: ['./quote-p-benefit-setting.scss'],
})
export class QuotePersonalBenefitSettingComponent implements OnInit {
    isDevMode: boolean = false;
    accessRole: AccessRole = new AccessRole();

    constructor(
        public quoteStepBenefit: QuoteStepBenefit,
        public quoteService: QuoteService,
        public router: Router,
        public dialog: MatDialog,
        public sharedFunction: SharedFunctionService
    ) {}

    ngOnInit(): void {
        this.isDevMode = this.sharedFunction.isQaSite();
    }

    changeDefaultFreqnency() {
        let newSetting: any = { DEF_FREQ: this.quoteStepBenefit.frequencyLoading.toString() };

        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                this.quoteStepBenefit.frequencyLoading = Number(response.QmSetting.DEF_FREQ);
            }
        });
    }

    openNeedAnalysis() {
        // update quote
        // this.quoteStepBenefit.onSave();
        // this.router.navigate(['/needanalysis/nature']);
    }

    crunch(reference: string) {
        // this.quoteStepBenefit.callCrunch(reference, false, null, null);
        // this.quoteStepBenefit.showReCrunchAlert = false;
    }

    printQuoteReport() {
        if (this.quoteStepBenefit.currentQuote.AppId > 0) {
            this.quoteStepBenefit.showDinoLoading();

            // get current select provider
            this.quoteStepBenefit.getQuoteReportByProvider(
                this.quoteStepBenefit.currentQuote.AppId,
                null,
                (response) => {
                    if (response) {
                        this.dialog.open(QuoteReportDialogComponent, {
                            data: response,
                            maxHeight: '90%',
                            maxWidth: '1140px',
                            width: '80vw',
                            disableClose: true,
                            autoFocus: false,
                            restoreFocus: false,
                        });
                    }
                    this.quoteStepBenefit.closeDinoLoading();
                }
            );
        }
    }
}
