<div class='p-5'>
  <ul class="list-group">
    <li class="list-group-item active">
      <h4 class='float-left'>Sky Banner - {{sharedFunction.getNameFromValue(typeOfPositionList, selectedPosition)}}</h4>
    </li>

    <li class='list-group-item border-top-dashed'>
      <mat-form-field>
        <mat-select placeholder="Select a Position"
                    (selectionChange)="loadAdsByPosition()"
                    [(ngModel)]="selectedPosition" required>
          <mat-option *ngFor="let position of typeOfPositionList"
                      [value]='position.Value'>{{position.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li class='list-group-item border-top-dashed'>
      <div class="row">
        <div class="col">
          <mat-form-field>
            <input matInput
                   placeholder="Search"
                   (keyup)="doSearch()"
                   [(ngModel)]="searchKeyword">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-select placeholder="Select a year"
                        (selectionChange)="doSearch()"
                        [(ngModel)]="searchYear">
              <mat-option [value]='0'>--</mat-option>
              <mat-option *ngFor="let year of searchYearList" [value]='year'>{{year}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-select placeholder="Select a type of banner"
                        (selectionChange)="doSearch()"
                        [(ngModel)]="searchFileType" required>
              <mat-option *ngFor="let banner of typeOfBannerList" [value]='banner.Value'>{{banner.Name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </li>
    <li class='list-group-item border-top-dashed text-right'>
      <ng-template *ngTemplateOutlet="addNewBanner"></ng-template>
    </li>
    <li class='list-group-item border-top-dashed'>
      <div class='table-responsive mt-3'>
        <table class="table table-hover table-bordered table-info">

          <tr>
            <th class='align-middle'>
              ID
            </th>
            <th class='align-middle'>
              Name
            </th>
            <th class='align-middle'>
              Start Date
              <button mat-icon-button aria-label="arrow_upward"
                      (click)='sortByStartDate=true; startDateAsc=!startDateAsc; sortByYear(qmads.List, true, startDateAsc)'>
                <span class="material-icons" *ngIf="startDateAsc">arrow_upward</span>
                <span class="material-icons" *ngIf="!startDateAsc">arrow_downward</span>
              </button>
            </th>
            <th class='align-middle'>
              End Date
              <button mat-icon-button aria-label="arrow_upward"
                      (click)='sortByStartDate=false; endDateAsc=!endDateAsc; sortByYear(qmads.List, false, endDateAsc)'>
                <span class="material-icons" *ngIf="endDateAsc">arrow_upward</span>
                <span class="material-icons" *ngIf="!endDateAsc">arrow_downward</span>
              </button>
            </th>
            <th class='align-middle'>
              Is Live?
            </th>
            <th class='align-middle'>
              Target URL
            </th>
            <th class='align-middle'>
              Type
            </th>
            <th class='align-middle'>
              Preview
            </th>
          </tr>

          <tbody class='bg-white'>
            <ng-container *ngFor="let qmad of qmads.List">
              <tr *ngIf="qmad.IsHide != true">
                <td>
                  {{qmad.Id}}
                </td>
                <td>
                  <button mat-button color="qm-blue" (click)="loadQmadDetail(qmad);" class="qmad-name">
                    {{qmad.Name}}
                  </button>
                </td>
                <td>
                  {{qmad.StartDate | date : 'dd MMM y'}}
                </td>
                <td>
                  {{qmad.EndDate | date : 'dd MMM y'}}
                </td>
                <td>
                  {{qmad.IsActive == true ? 'YES' : 'NO'}}
                </td>
                <td>
                  <a href="{{qmad.TargetUrl}}" target="_blank" class="qmad-target-url-link">
                    {{qmad.TargetUrl}}
                  </a>
                </td>
                <td>
                  {{qmad.FileType == 1 ? "IMAGE" : "HTML"}}
                </td>
                <td>
                  <ng-container *ngIf="qmad.FileType == 1">
                    <!-- display image -->
                    <img src="{{qmad.FileLocation}}" style="max-height: 200px;" />
                  </ng-container>
                  <ng-container *ngIf="qmad.FileType == 3 && qmad.Position != 9999">
                    <iframe [src]='qmad.FileLocation | safeUrl' width='200'
                            height='600' scrolling='no' style='border: none;'></iframe>
                  </ng-container>
                  <ng-container *ngIf="qmad.FileType == 3 && qmad.Position == 9999">
                    <div [innerHTML]="qmad.Description | safeHtml"></div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </li>
    <li class="list-group-item border-top-dashed" *ngIf="qmads.List.length <= 0">
      <div class="alert alert-danger" role="alert">
        No banners has been found.
      </div>
    </li>
    <li class='list-group-item border-top-dashed text-right'>
      <ng-template *ngTemplateOutlet="addNewBanner"></ng-template>
    </li>
  </ul>
</div>

<ng-template #addNewBanner>
  <button mat-flat-button color="qm-pumpkin"
          (click)='openAddNewBannerDialog();loginService.doGoogleTracking("InternalAdminPortal", "admin-ads", "add-new-ads")'>
    Add new Banner
  </button>
</ng-template>