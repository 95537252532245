import { Component, OnInit} from '@angular/core';
import { Qmads, QmadsList } from 'src/app/models/qmads';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { QmadsService } from '../../service/qmads.service';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBannerDialogComponent } from './add-banner-dialog/add-banner-dialog.component';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'qmads-admin',
    templateUrl: './qmads-admin.component.html',
    styleUrls: ['./qmads-admin.component.scss']
})


export class QmadsAdminComponent extends ComponentBaseClass implements OnInit {

    qmads: QmadsList;
    selectedPosition: number;
    selectedQmAds: Qmads;
    errorMessage: string;
    searchKeyword: string;
    searchFileType: number;
    searchYear: number;
  
    typeOfPositionList: QuoteDataObj[] = [];
    typeOfBannerList: QuoteDataObj[] = [];
    searchYearList: number[] = [];
    
    startDateAsc: boolean = false;
    endDateAsc: boolean = false;
    sortByStartDate: boolean = true;

    constructor(
      public loginService: LoginService,
      private qmadsService: QmadsService,
      private dataLoader: DataLoader,
      public sharedFunction: SharedFunctionService,
      private dialog: MatDialog
    ) { 
        super();
    }


    // load qmads
  ngOnInit() {
        this.typeOfPositionList = this.dataLoader.getRequiredData().SkyBannerPositionList;
        this.typeOfBannerList = this.dataLoader.getRequiredData().SkyBannerTypeOfBannerList;
        this.searchYearList = this.createSearchYearList();
        this.searchYear = (new Date()).getFullYear();
        this.selectedPosition = 1;
        this.qmads = new QmadsList();
        this.qmads.List = [];
        this.searchFileType = 1;
        this.searchKeyword = '';
        this.loadAdsByPosition();
    }


    loadAdsByPosition(){
        this.showDinoLoading();
        this.qmadsService.getAdminQmadses(this.selectedPosition, 
            (response) => {
                this.qmads = response;
                this.closeDinoLoading();

                this.doSearch();
        });
    }


    loadQmadDetail(qmad: Qmads){
        this.openAddNewBannerDialog(qmad);
    }


    doSearch(){
        // display all banners
        if(this.qmads && this.qmads.List && this.qmads.List.length > 0){
            this.qmads.List.forEach((qmad) => {                
                qmad.IsHide = false;
            });

            this.doKeyWordSearch();
            this.doFileTypeFilter();
            this.doYearSearch();
            
            this.sortByYear(this.qmads.List, this.sortByStartDate, this.sortByStartDate ? this.startDateAsc : this.endDateAsc)
        }
    }

    doKeyWordSearch(){
        if(this.qmads && this.qmads.List && this.qmads.List.length > 0){
            this.qmads.List.forEach((qmad) => {
                if(this.searchKeyword.length > 0){
                    // do key word filter
                    if(!qmad.Name.toLowerCase().includes(this.searchKeyword.toLowerCase())){
                        qmad.IsHide = true;
                    }
                }
            });
        }
    }

    doYearSearch(): void {
        if (this.qmads && this.qmads.List && this.qmads.List.length > 0) {
            this.qmads.List.forEach((qmad) => {
                if (this.searchYear !== 0) {
                  // do year filter
                    if (qmad.StartDate.slice(0, 4) !== this.searchYear.toString() && qmad.EndDate.slice(0, 4) !== this.searchYear.toString()) {
                        qmad.IsHide = true;
                    }
                }
              });
        }
    }

    doFileTypeFilter(){
        if(this.qmads && this.qmads.List && this.qmads.List.length > 0){
            this.qmads.List.forEach((qmad) => {
                if(qmad.IsHide == false){
                    if(this.searchFileType == 1){ // live banners only
                        if(qmad.IsActive != true){
                            qmad.IsHide = true;
                        }
                    }                
                    else if(this.searchFileType == 2){ // NOT live banners only
                        if(qmad.IsActive == true){
                            qmad.IsHide = true;
                        }
                    }
                }
            });
        }
    }

    save(){

    }
  
    createSearchYearList(): number[] {
        let searchYears: number[] = [];
        let startYear: number = 2014;
        let currentYear: number = (new Date()).getFullYear();
        let twoYearsLater: number = currentYear + 2;
        for (let i = startYear; i <= twoYearsLater; i++) {
            searchYears.push(i);
        }
        return searchYears;
    }

    sortByYear(qmads: Qmads[], byStartDate: boolean, ascending: boolean): void {
        if (byStartDate) {
            qmads.sort((a, b) => {
                let startDateCompare = new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime();
                if (startDateCompare !== 0) {
                    return this.startDateAsc ? startDateCompare : -startDateCompare;
                }
                return (this.endDateAsc ? 1 : -1) * (new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime());
            });
        } else {
            qmads.sort((a, b) => {
                let endDateCompare = new Date(a.EndDate).getTime() - new Date(b.EndDate).getTime();
                if (endDateCompare !== 0) {
                    return this.endDateAsc ? endDateCompare : -endDateCompare;
                }
                return (this.startDateAsc ? 1 : -1) * (new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime());
            });
        }
    }
    
    openAddNewBannerDialog(qmad?: Qmads): void {
        const dialogRef = this.dialog.open(AddBannerDialogComponent, {
            data: qmad ? qmad : null,
            maxWidth: '800px',
            width: "800px",
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.loadAdsByPosition();
                if (!qmad) {
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Created', 'Close', 2000);
                } else {
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close', 2000);
                }
            }
        });
    }
}
