import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { QmAds } from 'src/app/models/admin-qmAds/qmAds';
import { QmAdsPosition } from 'src/app/models/admin-qmAds/qmAds-position.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { DataLoader } from 'src/app/service/data.loader';
import { QmadsService } from 'src/app/service/qmads.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'add-banner-dialog',
  templateUrl: './add-banner-dialog.component.html',
  styleUrls: ['./add-banner-dialog.component.scss']
})
export class AddBannerDialogComponent implements OnInit {
  @ViewChild('contentContainer') contentContainer: ElementRef;
  advertisingObj: QmAds = new QmAds();
  startDate: Date = null;
  endDate: Date = null;

  typeOfBannerList: QuoteDataObj[] = [];
  typeOfPositionList: QmAdsPosition[] = [];
  selectedPosition: number;

  websitePrefix: string = 'https://';
  targetUrl: string = '';
  selectedFiles: FileList | null = null;
  previewImageUrl: SafeUrl = null;
  isNew: boolean = true;
  errorMessage: string = '';
  isLoadingData: boolean = false;
  isConfirmScreen: boolean = false;
  constructor (
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBannerDialogComponent>,
    public sharedFunction: SharedFunctionService,
    private dataLoader: DataLoader,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private qmadsService: QmadsService,
    @Inject(MAT_DIALOG_DATA) public data: QmAds
  ) {
  }

  ngOnInit(): void {
    this.advertisingObj = JSON.parse(JSON.stringify(this.data));
    if (this.data.Name) {
      this.isNew = false;
      this.startDate = new Date(this.advertisingObj.StartDate);
      this.endDate = new Date(this.advertisingObj.EndDate);
      this.targetUrl = this.advertisingObj.TargetUrl.substring(this.websitePrefix.length);
      this.previewImageUrl = this.advertisingObj.FileLocation;
    } else {
      this.advertisingObj.Id = -1;
      this.advertisingObj.FileType = 1;
      this.isNew = true;
    }
    this.typeOfBannerList = this.dataLoader.getRequiredData().SkyBannerTypeOfBannersList;
    this.getBannersPositionList();
  }

  getBannersPositionList() {
    this.qmadsService.getAdminQmAdsPositions(this.advertisingObj.BannerType,
      (response) => {
        this.typeOfPositionList = response;
        if (!this.typeOfPositionList.find(p => p.Position === this.advertisingObj.Position)) {
          this.advertisingObj.Position = this.typeOfPositionList[0].Position;
        }
      });
  }

  openConfirmScreen(): void {
    this.isConfirmScreen = true;
    this.updateAdvertising();
    this.contentContainer.nativeElement.scrollTop = 0;
  }

  save(): void {
    this.isLoadingData = true;
    this.qmadsService.addOrUpdateAdminQmads("item", this.advertisingObj, this.selectedFiles && this.selectedFiles.length > 0 ? this.selectedFiles[0] : null, (response) => {
      if (response && response.Message.MessageCode === 200) {
        this.dialogRef.close(this.advertisingObj);
      } else {
        this.errorMessage = response.Message.Message || this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
      }
      this.isLoadingData = false;
    });
  }

  close(): void {
    this.dialogRef.close(null);
  }

  selectFile(event: any): void {
    this.previewImageUrl = '';
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles.length > 0 && this.selectedFiles[0].type !== 'application/zip') {
      this.previewImageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFiles[0]));
    }
  }

  revokeObjectURL(url: string) {
    URL.revokeObjectURL(url);
  }

  updateAdvertising(): void {
    this.advertisingObj.BannerTypeName = this.typeOfBannerList.find(b => b.Value === this.advertisingObj.BannerType).Name;
    this.advertisingObj.PositionName = this.typeOfPositionList.find(p => p.Position === this.advertisingObj.Position).Name;
    this.advertisingObj.StartDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.advertisingObj.EndDate = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.advertisingObj.TargetUrl = this.websitePrefix + this.targetUrl;
    this.advertisingObj.FileLocation = this.previewImageUrl?.toString() || '';
  }
}
