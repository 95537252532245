export class Qmads {
    EndDate: string;
    FileLocation: string;
    FileType: number;
    Id: number;
    IsActive: boolean;
    Name: string;
    Position: number;
    ShowChance: number;
    StartDate: string;
    TargetUrl: string;
    ViewCount: number;
    Width: number;
    Height: number;
    Description: string;

    // for UI only
    IsHide: boolean;
}


export class QmadsList{
    List: Qmads[];
}