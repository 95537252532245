
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';


// app start
import { AppComponent } from './app.component';
import { PublicPageComponent } from './public.page.component';
import { PrivatePageComponent } from './private.page.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardArchivesComponent } from './components/dashboard/dashboard-archives.component';



import { HomeComponent } from './components/home/home.component';
import { RecentQuoteComponent } from './components/home/recent-quote.component';
import { BlogPageComponent } from './components/shared/blog-page.component';


// about
import { AboutComponent } from './containers/about/about.component';
import { AboutOverviewComponent } from './components/about/about-overview.component';
import { AboutUsingQuotemonsterComponent } from './components/about/about-using-quotemonster.component';
import { AboutFaqsComponent } from './components/about/about-faqs.component';

// login register forgotten password
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/login/signup.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { ContactComponent } from './components/contact/contact.component';
import { NoticeComponent } from './components/notice/notice.component';
import { UserNoticeDialog } from './components/notice/notice.component';
import { ForgottenPasswordDialogComponent } from './components/login/forgotten-password-dialog.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePaPasswordComponent } from './components/login/update-pa-password.component';


// quote component
import { QuoteStepMenuComponent } from './containers/quote/quote-step-menu.component';
import { QuoteClientComponent } from './components/quote/quote-client.component';
import { BenefitOrAnalysisSelectorDialogComponent } from './components/quote/benefit-analysis-selector-dialog.component';


import { QuotePersonalBenefitComponent } from './components/quote/quote-p-benefit.component';
import { QuotePersonalBenefitSelectorComponent } from './components/quote/quote-p-benefit-selector.component';
import { QuotePersonalBenefitListComponent } from './components/quote/quote-p-benefit-list.component';
import { QuotePersonalBenefitSettingComponent } from './components/quote/quote-p-benefit-setting.component';
import { QuotePersonalBenefitResultComponent } from './components/quote/quote-p-benefit-result.component';


import { QuoteCompareComponent } from './components/quote/quote-compare.component';
import { QuoteCompareChartsComponent } from './components/quote/quote-compare-charts.component';
import { QuoteCompareDocumentComponent } from './components/quote/quote-compare-document.component';
import { QuoteCompareResearchComponent } from './components/quote/quote-compare-research.component';
import { QuoteCompareHeadToHeadComponent } from './components/quote/quote-compare-h2h.component';
import { QuoteCompareUnderwritingComponent } from './components/quote/quote-compare-underwriting.component';
import { QuoteReportComponent } from './components/quote/quote-report.component';
import { QuoteResultDialogComponent } from './components/quote/quote-result-dialog.component';
// tslint:disable-next-line:max-line-length
import { AssumptionsGuideToProjectingPremiumDialogComponent } from './components/quote/assumptions-guide-to-projecting-premium-dialog.component';
import { QuoteReportDialogComponent } from './components/quote/quote-report-dialog.component';
import { SaveAndCloseComponent } from './components/quote/save-close-quote.component';
import { QuoteStepCompare } from './service/quote/quote.step.compare';
import { QuoteStepCompareChart } from './service/quote/quote.step.compare.chart';
import { QuoteStepCompareDocument } from './service/quote/quote.step.compare.document';
import { QuoteStepCompareResearch } from './service/quote/quote.step.compare.research';
import { QuoteStepCompareH2H } from './service/quote/quote.step.compare.h2h';
import { QuoteStepCompareUnderwriting } from './service/quote/quote.step.compare.underwriting';
import { QuoteStepReport } from './service/quote/quote.step.compare.report';
import { AppReportsDialogComponent } from './components/dashboard/app-reports-dialog.component';

// need analysis component
import { NcStepMenuComponent } from './containers/need-analysis/nc-step-menu.component';
import { NeedAnalysisNatureComponent } from './components/need-analysis/need-analysis-nature.component';
import { NeedAnalysisScopeComponent } from './components/need-analysis/need-analysis-scope.component';
import { NeedAnalysisRecommendationComponent } from './components/need-analysis/need-analysis-recommendation.component';
import { NeedAnalysisObjectivesComponent } from './components/need-analysis/need-analysis-objectives.component';
import { NeedAnalysisNeedCalComponent } from './components/need-analysis/need-analysis-need-cal.component';
import { NeedAnalysisFinancialComponent } from './components/need-analysis/need-analysis-financial.component';
import { NeedAnalysisExistingComponent } from './components/need-analysis/need-analysis-existing.component';
import { NeedAnalysisService } from './service/need.analysis.service';
import { SoaSectionDetailEditorDialog } from './components/setting/soa-section-detail-editor-dialog';

// setting component
import { SettingMenuComponent } from './containers/setting/setting-menu.component';
import { QuoteSettingComponent } from './components/setting/quote-setting.component';
import { CommissionSettingComponent } from './components/setting/commission-setting.component';
import { UserProviderSettingDialog } from './components/setting/quote-setting-dialog';
import { UserSettingComponent } from './components/setting/user-setting.component';
import { OccupationSettingComponent } from './components/setting/occupation-setting.component';
import { NeedSettingComponent } from './components/setting/need-setting.component';
import { BuyNowSettingComponent } from './components/setting/buy-now-setting.component';
import { CrmSettingComponent } from './components/setting/crm-setting.component';
import { OccupationDescriptionDialogSheet } from './components/setting/occupation-description-dialog-sheet.component';
import { SoaSettingComponent } from './components/setting/soa-setting.component';
import { OccupationSettingDialogComponent } from './components/setting/occupation-setting-dialog.component';
import { SecuritySettingComponent } from './components/setting/security-setting.component';
import { MainSettingComponent } from './components/setting/main-setting.component';
import { UserProfileComponent } from './components/setting/user-profile.component';
import { ResearchSettingComponent } from './components/setting/research-setting.component';

// research
import { ResearchMenuComponent } from './containers/research/research.menu.component';
import { ResearchHomeComponent } from './components/research/home/research-home.component';
import { ResearchHomeOurApproachComponent } from './components/research/home/research-home.ourApproach.component';
import { ResearchHomeFeaturesComponent } from './components/research/home/research-home.features.component';
import { ResearchHomeMethodologyComponent } from './components/research/home/research-home.methodology.component';
import { ResearchHomeFaqsComponent } from './components/research/home/research-home.faqs.component';
import { ResearchHomeAdvertisingComponent } from './components/research/home/research-home.advertising.component';
import { ResearchHomeInfographicsComponent } from './components/research/home/research-home.infographics.component';

import { ResearchToolsIndependentResearchComponent } from './components/research/tools/research-tools.independentResearch.component';
import { ResearchToolsFuneralPlansComponent } from './components/research/tools/research-tools.funeralPlans.component';
import { ResearchToolsBusinessOverheadsCoverComponent } from './components/research/tools/research-tools.businessOverheadsCover.component';
import { ResearchToolsDisabilityClaimsModelComponent } from './components/research/tools/research-tools.disabilityClaimsModel.component';
import { ResearchToolsKeyPersonRatingComponent } from './components/research/tools/research-tools.keyPersonRating.component';
import { ResearchToolsInsurerSelectionScreenComponent } from './components/research/tools/research-tools.insurerSelectionScreen.component';
import { ResearchPolicyWordingComponent } from './components/research/policy/research-policy-wording.component';
import { ResearchPolicyDocumentComponent } from './components/research/policy/research-policy-document.component';
import { ResearchPolicyWordingDialog } from './components/research/policy/research-policy-wording.dialog';
import { ResearchPolicyWordingV2Dialog } from './components/research/policy/research-policy-wording-v2.dialog';
import { ResearchToolsLegacyResearchComponent } from './components/research/tools/research-tools.legacyResearch.component';

// subscription
import { ResearchSubscriptionComponent } from './components/research/subscription/research-subscription.component';
import { ResearchSubscriptionPaymentsComponent } from './components/research/subscription/research-subscription-payments.component';
import { ResearchSubscriptionInvoicesComponent } from './components/research/subscription/research-subscription-invoices.component';
import { ResearchSubscriptionTermsComponent } from './components/research/subscription/research-subscription-terms.component';
import { ResearchSubscriptionExistingLicensesComponent } from './components/research/subscription/research-subscription-existing-licenses.component';
import { ResearchSubscriptionCompletePaymentComponent } from './components/research/subscription/research-subscription-completePayment.component';
import { ResearchLicenseOrderComponent } from './components/research/subscription/research-license-order.component';
import { ResearchLicenseOrderPaymentSelectComponent } from './components/research/subscription/research-license-orderPaymentSelect.component';
import { ResearchLicenseOrderPayOnlineDialogComponent } from './components/research/subscription/research-license-order-payonline-dialog.component';
import { ResearchLicenseOrderDetailComponent } from './components/research/subscription/research-license-order-detail.component';
// qm data service
import { LoginService } from './service/login.service';
import { FeedbackService } from './service/feedback.service';
import { ApiAddressService } from './service/api.address.service';
import { BlogService } from './service/blog.service';
import { DataLoaderService } from './service/data.loader.service';
import { DataLoader } from './service/data.loader';
import { QuoteService } from './service/quote.service';
import { QuoteStepClient } from './service/quote/quote.step.client';
import { QuoteStepBenefit } from './service/quote/quote.step.benefit';
import { UserService } from './service/user.service';
import { UserSecurityService } from './service/user.security.service';
import { SysConfigService } from './service/sys.config';
import { QprAdminService } from './service/qpr-admin.service';



// qm ads service
import { QmadsDirective } from './service/qmads.directive';
import { QmadsService } from './service/qmads.service';
import { QmadsPresentComponent } from './components/qmads/qmads-present.component';
import { AdHtmlComponent } from './components/qmads/ad-html.component';
import { AdImageComponent } from './components/qmads/ad-image.component';



// tools
import { SafeUrlPipe } from './service/safe.url.pipe';
import { SafeHtmlPipe } from './service/safe.html.pipe';
import { AutofocusDirective } from './service/autofocus.directive';
import { GeneralMessageDialogComponent } from './components/shared/general-message-dialog.component';
import { QuestionDialogComponent } from './components/shared/question-dialog.component';

import { CanDeactivateGuard } from './components/shared/can-deactivate-guard.service';
import { ConfirmMessageDialogService } from './components/shared/confirm-message-dialog.service';
import { ConfirmMessageDialogComponent } from './components/shared/confirm-message-dialog.component';
import { SharedFunctionService } from './service/shared.function.service';
import { ResizeService } from './service/screen.resize.service';

// routing
import { AppRoutingModule } from './app-routing.module';
import { Router, UrlSerializer } from '@angular/router';
import { ApiService } from './service/api.service';
import { LowerCaseUrlSerializer } from './tools/lower.case.url.serializer';
import { PricingComponent } from './components/home/pricing.component';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SideMenuComponent } from './containers/side-menu/side-menu.component';
import { UserMenuService } from './service/user.menu.service';
import { AdPublicShowComponent } from './components/qmads/ad-public-show.component';
import { GuideStartButtonComponent } from './components/shared/guide-start-button/guide-start-button.component';
import { BulkQuoteComponent } from './components/bulk-quote/bulk-quote.component';
import { NativeElementInjectorDirective } from './tools/native-element-injector.directive';
import { AutoSelectDirective } from './tools/auto-select.directive';
import { LegacyComponent } from './components/legacy/legacy.component';
import { SelectSearchComponent } from './components/shared/select-search/select-search.component';
import { CompareVersionDialogComponent } from './components/legacy/compare-version-dialog/compare-version-dialog.component';
import { CreateVersionComponent } from './components/legacy/create-version/create-version.component';
import { LegacyLeavingGuard } from './tools/legacy-leaving-guard.guard';
import { VersionDetailComponent } from './components/legacy/version-detail/version-detail.component';
import { DisplayNumberFormatterDirective } from './tools/display-number-formatter.directive';
import { DefaultValueDirective } from './tools/default-value.directive';
import { ReplacementTargetsComponent } from './components/soa-ai-questions/replacement-targets/replacement-targets.component';
import { StandaloneHeadToHeadComponent } from './components/legacy/standalone-head-to-head/standalone-head-to-head.component';
import { AiQuestionsComponent } from './components/soa-ai-questions/ai-questions/ai-questions.component';
import { AiPromptComponent } from './components/soa-ai-questions/ai-prompt/ai-prompt.component';
import { AddProviderDialogComponent } from './components/legacy/add-provider-dialog/add-provider-dialog.component';
import { AddProductDialogComponent } from './components/legacy/add-product-dialog/add-product-dialog.component';
import { AddItemDialogComponent } from './components/legacy/add-item-dialog/add-item-dialog.component';

import {QmQprProvProdMappingComponent} from './components/qm-qpr-prov-prod-mapping/qm-qpr-prov-prod-mapping.component';
import {QmQprProvProdMappingBusComponent} from './components/qm-qpr-prov-prod-mapping-bus/qm-qpr-prov-prod-mapping-bus.component';

import {AdminNoticeComponent} from './components/notice/admin/admin-notices.component';
import {AdminNoticesService} from './service/notice/admin-notices.service';

import {QmadsAdminComponent} from './components/qmads/qmads-admin.component';
import { CheckMonsterComponent } from './components/checkmonster/checkmonster.component';
import { AddBannerDialogComponent } from './components/qmads/add-banner-dialog/add-banner-dialog.component';
import { ThirdPartyCompaniesComponent } from './components/third-party-companies/third-party-companies.component';
import { AddCompanyDialogComponent } from './components/third-party-companies/add-company-dialog/add-company-dialog.component';
import { KiwiMonsterFundsListComponent } from './components/kiwimonster/kiwimonster-funds-list.component';
import { KiwiMonsterFundDetailComponent } from './components/kiwimonster/kiwimonster-fund-detail/kiwimonster-fund-detail.component';
import { KiwiMonsterMorningStarDialogComponent } from './components/kiwimonster/kiwimonster-morningstar-dialog/kiwimonster-morningstar-dialog.component';
import { AdminPolicyDocComponent } from './components/admin-policy-doc/admin-policy-doc.component';
import { ManageDataComponent } from './components/admin-policy-doc/manage-data/manage-data.component';
import { ManageDataDialogComponent } from './components/admin-policy-doc/manage-data-dialog/manage-data-dialog.component';
import { AddPolicyDocComponent } from './components/admin-policy-doc/add-policy-doc/add-policy-doc.component';
import { UpdatePolicyDocDialogComponent } from './components/admin-policy-doc/update-policy-doc/update-policy-doc.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicPageComponent,
    PrivatePageComponent,

    NavMenuComponent,
    DashboardComponent,
    DashboardArchivesComponent,

    LoginComponent,
    SignupComponent,
    VerifyDeviceComponent,
    ResetPasswordComponent,
    HomeComponent,
    BlogPageComponent,
    RecentQuoteComponent,
    ForgottenPasswordDialogComponent,
    UpdatePaPasswordComponent,

    AboutComponent,
    AboutOverviewComponent,
    AboutUsingQuotemonsterComponent,
    AboutFaqsComponent,

    ContactComponent,
    NoticeComponent,
    UserNoticeDialog,

    QuoteStepMenuComponent,
    QuoteClientComponent,
    BenefitOrAnalysisSelectorDialogComponent,

    QuotePersonalBenefitComponent,
    QuotePersonalBenefitSelectorComponent,
    QuotePersonalBenefitListComponent,
    QuotePersonalBenefitSettingComponent,
    QuotePersonalBenefitResultComponent,

    QuoteCompareComponent,
    QuoteCompareChartsComponent,
    QuoteCompareDocumentComponent,
    QuoteCompareResearchComponent,
    QuoteCompareHeadToHeadComponent,
    QuoteCompareUnderwritingComponent,
    QuoteReportComponent,
    OccupationDescriptionDialogSheet,
    QuoteResultDialogComponent,
    AssumptionsGuideToProjectingPremiumDialogComponent,
    QuoteReportDialogComponent,
    SaveAndCloseComponent,

    NcStepMenuComponent,
    NeedAnalysisNatureComponent,
    NeedAnalysisScopeComponent,
    NeedAnalysisRecommendationComponent,
    NeedAnalysisRecommendationComponent,
    NeedAnalysisObjectivesComponent,
    NeedAnalysisNeedCalComponent,
    NeedAnalysisFinancialComponent,
    NeedAnalysisExistingComponent,
    SoaSectionDetailEditorDialog,

    SettingMenuComponent,
    QuoteSettingComponent,
    CommissionSettingComponent,
    UserProviderSettingDialog,
    UserSettingComponent,
    OccupationSettingComponent,
    NeedSettingComponent,
    BuyNowSettingComponent,
    CrmSettingComponent,
    SoaSettingComponent,
    OccupationSettingDialogComponent,
    SecuritySettingComponent,
    MainSettingComponent,
    UserProfileComponent,
    ResearchSettingComponent,

    ResearchMenuComponent,
    ResearchHomeComponent,
    ResearchHomeOurApproachComponent,
    ResearchHomeFeaturesComponent,
    ResearchHomeFaqsComponent,
    ResearchHomeMethodologyComponent,
    ResearchHomeAdvertisingComponent,
    ResearchHomeInfographicsComponent,
    ResearchToolsIndependentResearchComponent,
    ResearchToolsFuneralPlansComponent,
    ResearchToolsBusinessOverheadsCoverComponent,
    ResearchToolsDisabilityClaimsModelComponent,
    ResearchToolsKeyPersonRatingComponent,
    ResearchToolsLegacyResearchComponent,
    ResearchToolsInsurerSelectionScreenComponent,
    ResearchPolicyWordingComponent,
    ResearchPolicyDocumentComponent,

    ResearchSubscriptionComponent,
    ResearchLicenseOrderComponent,
    ResearchLicenseOrderDetailComponent,
    ResearchLicenseOrderPayOnlineDialogComponent,
    ResearchLicenseOrderPaymentSelectComponent,
    ResearchSubscriptionExistingLicensesComponent,
    ResearchSubscriptionCompletePaymentComponent,
    ResearchSubscriptionPaymentsComponent,
    ResearchSubscriptionInvoicesComponent,
    ResearchSubscriptionTermsComponent,
    ResearchPolicyWordingDialog,
    ResearchPolicyWordingV2Dialog,

    GeneralMessageDialogComponent,
    QuestionDialogComponent,
    ConfirmMessageDialogComponent,
    AppReportsDialogComponent,

    QmadsDirective,
    QmadsPresentComponent,
    AdHtmlComponent,
    AdImageComponent,

    SafeUrlPipe,
    SafeHtmlPipe,
    AutofocusDirective,
    PricingComponent,
    SideMenuComponent,
    AdPublicShowComponent,
    GuideStartButtonComponent,
    BulkQuoteComponent,
    NativeElementInjectorDirective,
    AutoSelectDirective,
    LegacyComponent,
    SelectSearchComponent,
    CompareVersionDialogComponent,
    CreateVersionComponent,
    VersionDetailComponent,
    DisplayNumberFormatterDirective,
    DefaultValueDirective,
    ReplacementTargetsComponent,
    StandaloneHeadToHeadComponent,
    AiQuestionsComponent,
    AiPromptComponent,
    AddProviderDialogComponent,
    AddProductDialogComponent,
    AddItemDialogComponent,

    // QPR admin part
    QmQprProvProdMappingComponent,
    QmQprProvProdMappingBusComponent,

    // notice admin part
    AdminNoticeComponent,
    QmadsAdminComponent,
    CheckMonsterComponent,
    AddBannerDialogComponent,
    ThirdPartyCompaniesComponent,
    AddCompanyDialogComponent,
    KiwiMonsterFundsListComponent,
    KiwiMonsterFundDetailComponent,
    KiwiMonsterMorningStarDialogComponent,
    AdminPolicyDocComponent,
    ManageDataComponent,
    ManageDataDialogComponent,
    AddPolicyDocComponent,
    UpdatePolicyDocDialogComponent
  ],

  entryComponents: [
    AdHtmlComponent,
    AdImageComponent,
    UserProviderSettingDialog,
    UserNoticeDialog,
    OccupationDescriptionDialogSheet,
    BenefitOrAnalysisSelectorDialogComponent,
    QuoteResultDialogComponent,
    AssumptionsGuideToProjectingPremiumDialogComponent,
    QuoteReportDialogComponent,
    ResearchPolicyWordingDialog,
    ResearchPolicyWordingV2Dialog,
    OccupationSettingDialogComponent,
    GeneralMessageDialogComponent,
    ResearchLicenseOrderPayOnlineDialogComponent,
    QuestionDialogComponent,
    ForgottenPasswordDialogComponent,
    ConfirmMessageDialogComponent,
    AppReportsDialogComponent,
    SoaSectionDetailEditorDialog,
    CompareVersionDialogComponent,
    AddProviderDialogComponent,
    AddProductDialogComponent,
    AddItemDialogComponent,
    AddBannerDialogComponent,
    AddCompanyDialogComponent,
    KiwiMonsterMorningStarDialogComponent,
    ManageDataDialogComponent,
    UpdatePolicyDocDialogComponent
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    MatBottomSheetModule,
    DragDropModule,
    MatSidenavModule,

    AppRoutingModule,

    EditorModule
  ],
  providers: [
    FeedbackService,
    QmadsService,
    ApiAddressService,
    SysConfigService,
    LoginService,
    BlogService,
    DataLoaderService,
    DataLoader,
    QuoteService,
    QuoteStepClient,
    QuoteStepBenefit,
    QuoteStepCompare,
    QuoteStepCompareChart,
    QuoteStepCompareDocument,
    QuoteStepCompareResearch,
    QuoteStepCompareH2H,
    QuoteStepCompareUnderwriting,
    QuoteStepReport,
    UserService,
    UserSecurityService,
    MatStepperIntl,
    SharedFunctionService,
    NeedAnalysisService,
    ResizeService,

    CanDeactivateGuard,
    ConfirmMessageDialogService,

    ApiService,
    UserMenuService,
    DatePipe,
    LegacyLeavingGuard,
    QprAdminService,
    AdminNoticesService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },

    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-NZ",
    },

    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) { }
}
